// colors

$brand_primary: #ff9000; 
$brand_primary_light: #FEF9F3;
$brand_primary_dark: #EA7700; 
$brand_accent: #06A253; 
$brand_accent_dark: #03211D;
$brand_inverse: #1C59A0;
$brand_black: #353535; 
$color_text: #555555; 
$color_white: #f5f5f5; 
$color_grey_dark: #757575;
$color_grey_light: #c5c5c5;
$border_grey: #d5d5d5;
$border_grey_light: #E5E5E5;
$border_grey_dark: #959595;
$body_font: 'Work Sans',Roboto, Segoe UI, Open Sans, sans-serif;
$header_font: 'Work Sans', Roboto, Segoe UI, Open Sans , sans-serif;

$label_yellow: #ffd300;
$label_red: #D21F3C;
$label_green: #00A86B;
$label_purple: #702963;
$label_blue: #008ECC;
$label_pink: #EC5578;
$label_like_green: #1c2951;

$header_height: 60px;
$header-zindex: 1000;
$sidebar_width: 280px;