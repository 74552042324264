.media_block {
    margin-top: 10px;
}

.media_block_item {
    width: 100%;
    max-width: 120px;
    padding: 5px 0;
}

.tile_image {
    width: 100%;
    position: relative;
    margin-bottom: 10px;
}

.image_wrapper {
    background-color: $border_grey_light;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    border-radius: 5px;
    align-self: center;
    text-align: center;
    position: relative;
    padding: 0 0 130%;
    overflow: hidden;
    margin: 0 auto;
    display: block;
    width: 100%;

    .attachment_tile & {
        padding-bottom: 25%;
    }

    img {
        width: 100%;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        @include center(xy);
    }
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.upload_btn {
    cursor: pointer;
    fill: $border_grey_dark;
    @include flexbox();
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: $border_grey;
    border-radius: 5px;
    @include transition(all .4s ease-in-out);

    .image_wrapper &{
        position: absolute;
        @include center(xy);
        z-index: 100;
        opacity: .75;
    }

    &:hover, &:focus {
        background-color: rgba($brand_primary, .5);
        opacity: 1;
        fill: $brand_black;
    }
}

.attachment_tray {
    @include flexbox();
}

.attachment_tile {
    width: 140px;
}

.tray_item {
    margin-right: 15px;
}

.attachment_dismiss {
    position: absolute;
    z-index: 10;
    top: -10px;
    right: -10px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: white;
    border-radius: 50%;
    color: $border_grey_dark;
    font-size: 12px;
    font-family: sans-serif;
    font-weight: 700;
    border: 1px solid $border_grey;

    &.attach_bottom {
        top: auto;
        bottom: -10px;
    }

    .cust_icon {
        width: 10px;
        height: 10px;
    }

    &:hover, &:focus {
        background-color: $brand_primary;
        color: $color_white;
    }
}

.dialog_dismiss {
  position: absolute;
  top: -30px;
  right: 40px;
}

.media_item {
    @include flexbox();
    overflow: hidden;
}

.media_figure {
    position: relative;
    flex-shrink: 0;
    margin-right: 10px;

}

.media_info {
    flex: 1;
}

/*Custom Selectors*/
.custom_selector {
    @include flexbox();
    flex-wrap: wrap;
    margin-left: -7.5px;
    margin-right: -7.5px;
}

.selector_item {
    padding: 5px 7.5px;
}

.cst_selector {
    visibility: hidden;
    opacity: 0;
    width: 0;
    height: 0;
    display: none;
    position: absolute;
    top: 0; left: 0;
}

.pill_wrapper {
    position: relative;
    cursor: pointer;
    border: 1px solid $border_grey;
    @include flexbox;
    justify-content: space-between;
    padding: .45em 1.8em;
    text-align: center;
    border-radius: 40px;
    @include transition(all .4s ease-in-out);

    &:hover, &:focus, .selected & {
        background-color: $brand_black;
        border-color: transparent;
        color: $color_white;
    }
}

.objectWrapper {
    display: block;
    width: 100%;
    height: 100%;
    padding: 10px 15px;
    position: relative;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 10px;
    cursor: pointer;
    @include transition(all .4s ease-in-out);
    box-shadow: 1px 1px 3px rgba($brand_black, .3);

    .new_card & {
        box-shadow: unset;
        border: 2px dashed $border_grey;
        background-color: transparent;
        justify-content: center;
        align-items: center;
    }

    &:hover, &:focus {
        background-color: $brand_primary_light;
    }

    .selected & {
        background-color: rgba($brand_primary, .1);
    }
}

.new_item {
    font-size: 1.6rem;
    display: block;
    letter-spacing: .1rem;
    font-family: $header_font;
}

.selectInput {
    position: absolute;
    top: 15px;
    right: 10px;
}

.selector_label {
    color: inherit;
    font-size: 13px;
    display: block;
    line-height: 30px;
    text-transform: capitalize;
    @include text_truncate();
}

.object_card {
    width: 100%;

    @include MQ(S) {
        width: 50%;
    }

    @include MQ(M) {
        width: 33.33%;
    }
}

.color_tile {
    width: 33.33%;
    height: 50px;
}

.color_wrapper {
    display: block;
    width: 100%;
    height: 100%;
    padding: 5px 7.5px;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    color: $color_white;

    &:hover, &:focus {
        opacity: .85;
    }
}

.select_indicator {
    position: absolute;
    right: 5px;
    @include center(y);
    text-align: center;
    visibility: hidden;
    opacity: 0;
    border-radius: 50%;
    color: inherit;
    color: $color_white;
    @include transition(all 0.4s ease-in-out);

    &::after {
        content: "\2714";
    }

    .selected & {
        visibility: visible;
        opacity: 1;
    }
}

.detail_control {
    display: block;
    clear: both;
    margin-bottom: 20px;
}

.back_btn {
    font-size: 14px;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    letter-spacing: -0.05em;
    

    &:hover, &:focus {
        text-decoration: none;
        color: $brand_primary;
        opacity: .75;
        cursor: pointer;
    }
}

.back_icon {
    margin-right: 5px;
    width: 24px;
    flex-shrink: 0;

    img {
        display: inline-block;
    }
}

.more_control {
    display: block;
    clear: both;

    .form-control + & {
        margin-top: 10px;
    }
}

.t_icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid $border_grey;
    flex-shrink: 0;
    margin-right: 10px;
    fill: $brand_primary;
    align-items: center;
    justify-content: center;
    display: flex;
    @include transition(all .4s ease-in-out);

    svg {
        width: 12px;
        height: 12px;;
    }

    .new_card & {
        width: 48px;
        height: 48px;

        svg {
            width: 24px;
            height: 24px;;
        }
    }


}

.input_control {
    font-size: 1.4rem;
    display: inline-flex;
    align-items: center;
    padding-bottom: 2px;
    border-bottom: 1px dotted transparent;
    @include transition(all .4s ease-in-out);
    cursor: pointer;

    &:hover, &:focus {
        color: $brand_primary;

        .t_icon {
            border-color: $brand_primary;
        }
    }
}

// Countdown timer

.countdown_timer_block {
    clear: both;
    width: 100%;
}

.countdown {
    @include flexbox();
    text-transform: uppercase;
    display: flex;
    line-height: 1;
    align-items: center;
    justify-content: center;
}

.time_entity {
    @include flexbox();
    flex-direction: column;
    letter-spacing: .015em;
    text-align: center;
    justify-content: center;
}

.timer_entity_seperator {
    font-size: 21px;
    color: $border_grey_dark;
    padding: 0 10px;
}

.countdown-heading {
    font-size: 11px;
    color: $color_grey_dark;
    margin-bottom: 5px;
}

.countdown-value {
    font-size: 24px;
    font-weight: 700;
    color: $brand_black;
}

//

.calendar_block {
    width: 100%;
    height: 100vh;
}

.calControlGroup {
    @include flexbox();
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.calBtnGroup {
    border: 1px solid $border_grey;
    border-radius: 25px;
    overflow: hidden;
    display: flex;
}

.calBtn {
    display: flex;
    text-align: center;
    padding: 7.5px 15px;
    font-size: 11px;
    text-transform: uppercase;
    cursor: pointer;
    @include transition(all .4s ease-in-out);

    + .calBtn {
        border-left: 1px solid $border_grey;
    }

    &:hover, &:focus {
        background-color: $brand_black;
        fill: $color_white;
        color: $color_white;
    }

    &.active {
        background-color: $brand_black;
        color: $color_white;
    }
}

@include MQ(S) {
    .calControlButtons {
        justify-content: flex-end;
    }
}

.iconBtn {
    justify-content: center;
    align-items: center;
}

.calControlHeader {
    margin-bottom: 20px;
}

.calInfoItem {
    @include flexbox();
    + .calInfoItem {
        margin-left: 15px;
    }
}

.cal-month-view {
    .cal-day-badge {
        background-color: $brand_accent_dark;
    }

    .cal-header {
        font-weight: 400;
        font-size: 13px;
        text-transform: uppercase;
    }

    .cal-day-number {
        opacity: .85;
    }

    .cal-day-cell {
        &.cal-out-month .cal-day-number{
            opacity: .35;
        }

        &.cal-weekend .cal-day-number {
            color: $brand_inverse;
        }

        &.cal-today {
            background-color: rgba($brand_primary, .1);
        }
    }

    .cal-open-day-events {
        background-color: $brand_accent_dark;
    }

}

.cal-week-view {
    .cal-event {
        border-color: transparent;
        color: $color_white;
    }

    .cal-header.cal-today  {
        background-color: rgba($brand_primary, .1);
    }
}

.cal-day-view {
    .cal-event {
        color: $color_white;
    }
}

.actionTile {
    width: 100%;
    display: block;

    &:not(:last-of-type) {
        margin-bottom: 10px;
    }

}

.actionTileWrapper {
    text-align: left;
    padding: 10px 15px;
    line-height: 1.5;
    border-radius: 4px;
    background-color: transparent;
    @include transition(background-color .4s ease-in-out);

    &:hover, &:focus {
        background-color: rgba($brand_primary, .1);
    }
}

// FIlter

.pageFilterBar {
    clear: both;
    margin-bottom: 15px;
}

.filterOption {

    @include MQ(M) {
        min-width: 150px;;
    }

    + .filterOption {
        border-left: 1px solid $border_grey_light;
    }
}

.optionsearch {
    flex: 2 0 auto;
}

.filterOptionsPanel {
    background-color: white;
    border: 1px solid $border_grey_light;
}

.filterOptionPanelHeader {
    @include flexbox();
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.filterOptionView  {
    padding: 15px;
    border-top: 1px solid $border_grey_light;


    @include MQ(M) {
        padding: 15px 30px;
    }
}

.filterOptionWrapper {
    @include flexbox();
    align-items: center;
    width: 100%;
    padding: 10px 15px;
    fill: $border_grey_dark;
    color: $color_grey_dark;
    position: relative;

    &:hover, &:focus {
        color: $brand_black;
        fill: $brand_primary;
    }

    &:active {
        outline: none;
    }
}

.optionIcon + .optionLabel {
    margin-left: 7.5px;
}

.optionLabel {
    flex-grow: 1;

    @include MQ-MAX(M) {
        display: none;
    }
}

.viewToggle {
    @include flexbox();
    margin: 0;
}

.viewToggleItem {
    padding: 0 7.5px;
}

.viewToggleItemLink {
    @include flexbox();
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: transparent;
    @include transition(all .4s ease-in-out);

    &:hover, &:focus {
        background-color: $border_grey_light;
        fill: $border_grey_dark;
    }

    &.active  {
        background-color: $brand_accent_dark;
        fill: $color_white;
    }
}

.filterSearchBar {
    width: 100%;
    @include flexbox();
}

.filterParamBlock {
    @include flexbox();
    flex-direction: column;
    margin-bottom: 20px;
}

.paramsHeading {
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid $border_grey_light;
    color: $color_grey_dark;
}

// collapsible panels
.collapsibleBlock {
    display: block;
    @include transition(height .6s ease-in-out);

    > p {
        line-height: 1.5em;
        height: auto; 
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
    }

    &.blockCollapsed {
        > p {
            height: 6em;
        }
    }
}

.collapseInner {
    height: auto;
    overflow: hidden;
    @include flexbox();
    flex-direction: column;

    .blockCollapsed & {
        height: 0;
        display: none;
        visibility: hidden;
        opacity: 0;
        @include transition(visibility .6s ease-in-out, opacity .6s ease-in-out, height .6s ease-in-out);
    }
}


.collapsetoggle {
    clear: both;
    display: block;
    margin-top: 7.5px;
    color: $brand_inverse;
    width: 100%;

    &:hover, &:focus {
        text-decoration: underline;
        color: $brand_accent
    }
    
    &::after {
        content: "Show Less";
        display: inline-block;
        
        .blockCollapsed & {
            content: "Show More";
        }
    }
}

.expandCollapseSm {
    text-align: center;
    margin: 10px auto 0;

    @include MQ(M) {
        display: none;
    }
}
