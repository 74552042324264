.main_content_wrapper {
    position: relative;
    width: 100%;
    z-index: 2;
    min-height: 400px;
    padding-top: 4px;
    
    &::before {
        /* never visible - used to check MQ in jQuery */
        display: none;
        content: 'mobile';
    }
    @include MQ(M) {
        &::before {
            /* never visible - used to check MQ in jQuery */
            content: 'tablet';
        }
    }
    @include MQ(L) {
        margin-top: 0;
        &::before {
            /* never visible - used to check MQ in jQuery */
            content: 'desktop';
        }
    }
}

.section_block {
    padding: 70px 0;
    display: block;
    width: 100%;
    position: relative;
}

.section_cto {
    margin-top: 30px;
    display: block;
    clear: both;
}

.section_header {
    padding-bottom: 40px;
    position: relative;
}

.section_title {
    font-size: 28px;
    margin-bottom: 15px;
    letter-spacing: -0.015em;
    line-height: 1.25;
    color: $brand_black;

    .cm_page_header & {
        margin: 0;
    }
    
    @include MQ(M) {
        font-size: 32px;
    }
}

.title {
    letter-spacing: -0.015em;
    line-height: 1.25;
    color: $brand_black;
    font-size: 16px;
}

.text_clipped {
    @include text_truncate();
}

.title_sm {
    font-size: 18px;
    
    @include MQ(M) {
        font-size: 21px;
    }
}

.title_lg {
    font-size: 24px;
    
    @include MQ(M) {
        font-size: 32px;
    }
}

.title_sub {
    display: inline-block;
    vertical-align: super;
    margin-left: 7.5px;
}

.edit_sub {
    font-size: 11px;
    padding: 5px 10px;
    background-color: $brand_black;
    color: $color_white;
    border-radius: 5px;
    margin-left: 5px;
    opacity: .65;
    @include transition(all .4s ease-in-out);

    &:hover, &:focus {
        opacity: 1;
    }
}

footer[role="contentinfo"] {
    width: 100%;
    clear: both;
    color: $border_grey;
    background-color: $brand_accent_dark;
    @include background(radial-gradient(farthest-side at 50% 120%, $brand_accent_dark 0, tint(black, 5)  960px));
}

.footerRow {
    display: block;
    padding: 40px 0;
}

.footerTop {
    border-bottom: 1px solid rgba($brand_accent, .15);
    padding-top: 70px;
}

.brand_media {
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;

    img {
        display: inline-block;
        max-width: 200px;
    }
}

.footer_block {
    @include flexbox();
    flex-direction: column;
    color: $border_grey;
    padding-top: 15px;
    padding-bottom: 15px;
}

.ft_block_header {
    margin-bottom: 15px;
}

.item_title {
    font-size: 16px;
    text-transform: capitalize;
    letter-spacing: 0.015;
}

.ft_block_segment + .ft_block_segment {
    margin-top: 15px;
}

.segment_flex {
    @include flexbox();
    align-items: flex-start;
    justify-content: space-between;
}

.ft_nav_block > li {
    line-height: 2.3em;
}

.copyright_notice {
    color: $border_grey_light;
    padding-top: 15px;
    font-size: 13px;
    opacity: .65;
}

.tooltip-inner {
    font-size: 12px;
}

.content_inner {
    padding-bottom: 70px;
}

.section_group {
    display: block;
    margin-bottom: 50px;

    &:last-child {
        margin-bottom: 0;
    }
}

.section_group_header {
    display: block;
    clear: both;
    margin-bottom: 15px;
}

.info_title {
    font-size: 21px;
    margin-bottom: 20px;
    clear: both;
    color: $brand_black;
}

.insight_box {
    clear: both;
    margin-bottom: 20px;
    width: 100%;
}

.box_wrapper {
    padding: 15px;
    display: block;
    border-radius: 10px;
    width: 100%;
    border: 1px solid $border_grey;
    background-color: transparent;

    .box_filled_normal &{
        background-color: white;
        border: none;
    }
} 

// Right sidebar

.paddedWrapper {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    @include flexbox();
    flex-direction: column;
}

.rightSidebarHeader {
    margin-bottom: 20px;
    line-height: 28px;
}

.sectionCTABlock {
    @include flexbox();
    margin-top: 50px;
    align-content: center;
    align-items: center;
    justify-content: space-between;

    @include MQ-MAX(M) {
        flex-direction: column;
        text-align: center;
        justify-content: center;
    }
}

.CTALabel {
    color: $brand_accent_dark;
    line-height: 165%;

    @include MQ-MAX(M) {
        margin-bottom: 15px;
    }

    @include MQ(M) {
        flex-basis: 75%;
    }
}

.infoBanner {
    display: block;
    width: 100%;
}

.bannerWrapper {
    background-color: $brand_primary;
    @include background(linear-gradient(180deg, $brand_primary 50%, $brand_primary_dark 100%));
    border-radius: 40px;
    position: relative;
    @include flexbox();
    align-content: center;
    padding: 20px;
    justify-content: space-between;


    @include MQ(M) {
        padding: 40px 80px;
    }
}

.bannerTextHolder {
    width: 81%;
    font-size: 18px;
    padding-right: 3%;

    @include MQ(M) {
        line-height: 165%;
        font-size: 32px;
    }
}

.figure_bg {
    position: absolute;
    right: -15px;
    bottom: 0px;

    img {
        max-width: 150px;

        @include MQ(M) {
            max-width: 380px; 
        }
    }
}

.customers {
    padding-top: 40px;
}

.client_logos {
    justify-content: center;
    align-content: center;
}

.custLogo {
    padding: 15px;
}

.custLogoWrapper {
    width: 100%;
    text-align: center;

    img {
        display: inline-block;
        height: 45px;
        width: auto;
        mix-blend-mode: multiply;
    }
}