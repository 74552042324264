::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $border_grey_dark;
}

 ::-moz-placeholder {
    /* Firefox 19+ */
    color: $border_grey_dark;
}

 :-ms-input-placeholder {
    /* IE 10+ */
    color: $border_grey_dark;
}

 :-moz-placeholder {
    /* Firefox 18- */
    color: $border_grey_dark;
}

fieldset {
    padding: 20px 0;

    + fieldset {
        border-top: 1px solid $border_grey;
    }

    >p {
        margin-bottom: 8.5px;
    }
}

.input-group {
    z-index: 1;
    +.input-group {
        margin-top: 1em;
    }
}

.input-group-addon {
    background: $brand_black;
    color: $border_grey_light;
    font-size: .923em;
    padding: .75em 1em;
    align-self: center;
    height: 100%;
    border-radius: 0 5px 5px 0
}

.input-group-btn {
    font-size: 1em;
    .btn {
        vertical-align: top;
        padding-right: 1.5em;
        padding-left: 1.5em;
    }
}

.form-horizontal {
    @include flexbox();
    @include MQ(M) {
        margin-bottom: 20px;
    }
}

.slim_form {
    max-width: 500px;
    clear: both;
}

.form_row_group {
    margin-bottom: 20px;
    position: relative;
}

.form_group_item {
    position: relative;
}

@include MQ(S) {
    .text_right_sm {
        text-align: right;
    }
}

.form_row_title {
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: normal;

    @include MQ(M) {
        margin-top: 10px;
        margin-right: 20px;
    }
}

.form-group {
    position: relative;
    margin-bottom: 20px;
    .checkbox-inline {
        margin-top: 0;
    }
    >a:not(.btn) {
        display: inline-block;
        margin-top: 10px;
    }
}

input {
    &:focus {
        outline: none;
    }
}

.help-block {
    font-size: .85em;
    color: $color_grey_dark;
    font-style: italic;
    margin: 7.5px 0;
}

.form-control {
    padding: 8px 12px;
    font-size: 14px;
    line-height: normal;
    top: 0;
    left: 0;
    z-index: 1;
    margin: 0;
    outline: 0;
    width: 100%;
    min-height: 40px;
    position: relative;
    border: 1px solid $border_grey;
    border-radius: 5px;
    overflow: hidden;
    color: $color_text;
    text-indent: .01px;
    @include appearance(none);
    @include box-shadow(0 0 0, transparent);
    vertical-align: middle;
    &.width_auto {
        width: auto;
    }
    &.title_input {
        font-size: 18px;
        padding: 12px 16px;
    }
    &.field_rounded {
        border-radius: 25px;
        padding: 15px 20px;
    }

    .messageInputControl & {
        border: none;
        padding-left: 0;
        padding-right: 0;
        background-color: transparent;
    }

    &:focus {
        outline: 0;
        border-color: $brand_primary;
        box-shadow: none;
    }

    &:disabled, &[readonly] {
        border: none;
    }
}

textarea {
    box-sizing: border-box;
    vertical-align: middle;
    cursor: text;
    resize: none;
}

.prefixed_input {
    padding-left: 40px;
}

.form_mng {
    max-width: 450px;
}

.date_selector_widget {
    @include flexbox();
    align-items: center;

    + .date_selector_widget {
        margin-top: 20px;
    }
}

.date_item {
    width: 120px;
    position: relative;
    &:not(:last-of-type) {
        margin-right: 15px;
    }
}

.date_month {
    width: auto;
    flex-grow: 1;
}

select.form-control {
    background: white url(/assets/img/icons/arrow-down.png) right center no-repeat;
    padding-right: 25px;
    &:focus {
        outline: none;
        background: white url(/assets/img/icons/arrow-down.png) right center no-repeat;
    }
}

.form_link {
    color: $brand_primary;
    font-weight: 600;
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    display: block;
    &:hover,
    &:hover {
        opacity: .9;
        text-decoration: underline;
    }
}

.radio,
.checkbox {
    line-height: 1;
    +.radio,
    +.checkbox {
        margin-top: 15px;
    }
}

input[type="radio"],
.radio input[type="radio"],
.radio-inline input[type="radio"] {
    position: relative;
    margin-top: 0;
    margin-right: 7.5px;
    vertical-align: top;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    &:focus {
        outline: none;
    }
    &:before,
    &:after {
        content: "";
        display: block;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        top: 3px;
        @include transition(240ms);
        .control_flex & {
            top: 0;
        }
    }
    &:before {
        position: absolute;
        left: 0;
        background-color: $brand_primary;
        @include transform(scale(0));
    }
    &:after {
        position: relative;
        border: 1px solid $color_grey_dark;
    }
    &:checked:before {
        @include transform(scale(.5));
    }
    &:disabled:checked:before {
        background-color: $border_grey_dark;
    }
    &:checked:after {
        border-color: $brand_primary;
    }
    &:disabled:after,
    &:disabled:checked:after {
        border-color: $border_grey_dark;
    }
}

input[type="checkbox"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
    position: relative;
    border: none;
    margin-bottom: -4px;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    &:focus {
        outline: none;
    }
    &:after {
        content: "";
        display: block;
        width: 18px;
        height: 18px;
        margin-top: -2px;
        margin-right: 7.5px;
        border: 1px solid $brand_black;
        border-radius: 3px;
        @include transition(240ms);
    }
    &:checked:before {
        content: "";
        position: absolute;
        top: 0;
        left: 6px;
        display: table;
        width: 6px;
        height: 12px;
        border: 1px solid $color_white;
        border-top-width: 0;
        border-left-width: 0;
        @include transform(rotate(45deg));
    }
    &:checked:after {
        background-color: $brand_primary;
        border-color: $brand_primary;
    }
    &:disabled:after {
        border-color: $border_grey_dark;
    }
    &:disabled:checked:after {
        background-color: $border_grey_light;
        border-color: $border_grey;
    }
    &:disabled:checked:before {
        border-color: $border_grey_dark;
    }
}

input[type=radio],
input[type=checkbox] {
    margin-top: 0;
}

.control-label {
    font-size: 1.4rem;
    display: inline-block;
    color: $border_grey_dark;
    letter-spacing: .1rem;
    margin-bottom: 0;
    padding-bottom: 7.5px;
    cursor: pointer;
    [class*="checkbox"] &,
    [class*="radio"] & {
        color: inherit;
    }
}

.required::after {
    content: "*";
    font-size: 1.15em;
    padding-left: 4px;
    color: red;
}

.radio-inline,
.checkbox-inline {
    padding: 10px 0 5px;

    .form-row &{
        padding: 0;
    }
}

label {
    font-weight: normal;
    &.radio-inline {
        +label {
            margin-left: 15px;
        }
    }
    &.checkbox-inline {
        margin: 5px 0;
        margin-bottom: 0 !important;
        font-size: 14px;
        display: inline-block;
        vertical-align: top;
    }
}

.form_body {
    display: block;
    max-width: 720px;
    padding-top: 30px;
}

.control_flex {
    @include flexbox();
    padding: 15px;
    background-color: transparent;
    border: 1px solid $border_grey;
    border-radius: 5px;
    @include transition(background-color .4s);
    cursor: pointer;
    text-align: left;
    +.control_flex {
        margin-top: 30px;
    }
    &:hover,
    &:focus, &.active {
        background-color: rgba($brand_primary, .15);
        color: inherit;
        border-color: rgba($brand_primary, .5);
    }
}

.form_ind {
    flex-shrink: 0;
}

.form_info_block {
    flex-grow: 1;
    font-size: 14px;
    line-height: 1.65;
    margin-top: -7.5px;
    padding-left: 7.5px;
    >span {
        display: block;
    }
}

.label_title {
    font-size: 14px;
    margin-bottom: 5px;
    line-height: 24px;
    font-weight: 600;
    color: $brand_black;
}

.label_text {
    font-size: 14px;
    line-height: 21px;
}

.form_cto {
    display: block;
    clear: both;
    margin-top: 40px;
    padding-top: 20px;
    border-top: 2px solid $border_grey;
}

.profile_image_thumb {
    width: 200px;
    height: 200px;
    margin-bottom: 50px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
}

.avi_upload_button {
    background-color: rgba($color_white, .85);
    fill: $brand_black;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    padding: 12px 15px;
    margin: 0;
    @include transition (all .4s ease-in-out);
    &:hover {
        background-color: $brand_primary;
        fill: $color_white;
    }
}

.button_docked {
    line-height: 1;
    width: 100%;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1;
}

.input_control {
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    &:hover,
    &:focus {
        color: $brand_primary;
        .t_icon {
            border-color: $brand_primary;
            fill: $brand_primary;
        }
    }
}

.t_icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px dashed $border_grey_dark;
    flex-shrink: 0;
    margin-right: 10px;
    fill: $border_grey_dark;
    align-items: center;
    justify-content: center;
    display: flex;
    svg {
        width: 12px;
        height: 12px;
        ;
    }
}


/*Loader*/

@keyframes spinner_spin {
    0% {
        transform: rotate(0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
        transform: rotate(900deg);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
        transform: rotate(1800deg);
    }
}

.cm_spinner {
    display: inline-block;
    position: absolute;

    .has_loader & {
        @include center(xy);
    }

    .form-group & {
        right: 7px;
        top: 35px;
    }

    &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 0;
        height: 0;
        margin: 5px;
        box-sizing: border-box;
        border: 10px solid $brand_inverse;
        border-color: $brand_primary transparent $brand_black transparent;
        animation: spinner_spin 2.4s infinite;
    }
}

.loading {
    overflow: hidden;
}

.loader_item {
    z-index: 10;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    .has_loader & {
        background-color: rgba(white, .85);
    }

    .loading & {
        visibility: visible;
        opacity: 1;
    }
}

.filter_navigation {
    display: block;
    margin-bottom: 30px;
}

.filter_menu,
.filter_search {
    padding: 7.5px 0;
}

.filter_nav_options {
    @include flexbox();
    overflow-x: auto;
    margin: 0 -10px;
    li {
        padding: 5px 10px;
    }
    a {
        font-weight: 700;
        font-size: 12px;
        display: block;
        padding: 7px 21px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        border-radius: 40px;
        @include transition(all .4s ease);
        &.active,
        &:hover,
        &:focus {
            color: $brand_black;
            background-color: $border_grey_light;
        }
    }
}

.filter_search {
    border-top: 1px solid $border_grey;
    border-bottom: 1px solid $border_grey;
}

.search_bar_holder {
    @include flexbox();
}

.search_btn{
    flex-shrink: 0;
    margin-right: 15px;
    .cust_icon {
        stroke: $brand_primary;
        stroke-width: 2;
    }
}

.search_input, .linearInput {
    flex: 1;
    width: 100%;
    position: relative;
    margin: 0;
}

.filter_search_input, .linearInputform {
    width: 100%;
    display: block;
    border: none;
    outline: 0;
    background-color: transparent;
}

.has_suggestion {
    position: relative;
}

.search_suggestion_widget {
    z-index: 3000;
    width: 100%;
    top: 42px;
    left: 0px;
    position: absolute;
    display: none;

    .has_suggestion & {
        display: block;
    }
}

.widget_wrapper {
    background-color: white;
    border: 1px solid $border_grey_light;
    border-radius: 5px;
    box-shadow: 1px 1px 4px rgba($brand_black, .15);
    width: 100%;
    height: auto;
}

.search_result_list {
    clear: both;
    margin: 0;
    padding: 5px 0;

    .no_match & {
        display: none;
    }
}

.search_result_empty, .search_result_item, .notificationItem {
    position: relative;
    width: 100%;
}

.search_result_item {
    list-style-type: none;
    cursor: pointer;
    font-size: 14px;
}

.result_item_wrapper {
    display: block;
    width: 100%;
    height: 100%;
    padding: 5px 15px;
    background-color: transparent;

    &.docWrapper {
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 5px;
    }

    &:hover, &:focus {
        color: inherit;
        background-color: $color_white;
    }

    .active & {
        background-color: rgba($brand_primary, .15);
    }
}

.search_result_empty {
    display: none;
    padding: 10px 15px;
    

    .no_match & {
        display: block;
    }
}

.user_result_block {
    @include flexbox();
    align-items: center;
    height: 35px;
    line-height: 35px;
}

.item_result_block {
    padding-top: 5px;
    padding-bottom: 5px;
}

.result_display_name {
    flex: 0 1 auto;
    @include text_truncate();
}

.search_result_avi {
    margin-right: 10px;
    font-size: 10px;
}

.upload_dialog {
    clear: both;
    width: 100%;
    margin-bottom: 50px;
}

.upload_control {
    border: 1px dashed $border_grey;
    background-color: $border_grey_light;
    @include flexbox();
    flex-direction: column;
    padding: 30px;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    fill: $border_grey_dark;
    @include transition(all .4s ease-in-out);

    &:hover, &:focus {
        fill: $brand_black;
        border-color: $brand_primary;
        background-color: $brand_primary_light;
    }
}

.upload_message {
    margin-top: 15px;
}

// Upload Preview

.upload_item {
    padding: 10px;
    background-color: white;
    border: 1px solid $border_grey_light;
    border-radius: 5px;
    box-shadow: 1px 0 3px $border_grey;
    position: relative;
    margin-bottom: 10px;

}

.file_item {
    @include flexbox();
    align-items: center;
    width: 100%;
}

.file_description, .fileUploadProgress {
    clear: both;
    margin-top: 10px;
    width: 100%;
} 

.fileUploadProgress {
    height: 5px;
}

// Email forms

.mailFormItem {
    position: relative;
    margin-bottom: 15px;
}

.linearInputform {
    padding-left: 70px;
    box-shadow: inset 0 -1px 0 $border_grey;
    padding-bottom: 8px;
    padding-top: 8px;
    @include transition(all .4s ease-in-out);

    &:focus {
        box-shadow: inset 0 -2px 0 $brand_primary;
    }
}

.messageTextarea {
    padding: 10px 0;
    width: 100%;
    display: block;
    border: none;
    outline: 0;
    background-color: transparent;
}

.formLabel {
    position: absolute;
    top: 10px;
    left: 0;
    font-size: 14px;
    font-weight: 600;
    color: $color_grey_dark;
}

.listSearchFilter {
    @include flexbox();
    justify-content: space-between;
    align-items: center;
}

.filterSearchControl, .drawerSearch {
    @include flexbox();
    align-items: center;
    justify-content: space-between;
    flex: 1;
    border: 1px solid $border_grey_light;
    border-radius: 5px;
    cursor: pointer;
    background-color: white;
}

.SearchInput{
    border: none;
    padding: 0;
    background-color: transparent;
    flex-grow: 1;
    padding: 10px 12px;
}

.filterPermission {
    flex-shrink: 0;
    padding-left: 10px;
}

.filterSearchAction {
    flex-shrink: 0;
    margin-left: 10px;
}

.drawerSearch {
    background-color: $color_white;
    border: 1px solid $border_grey;
    border-radius: 30px;

    .filter_search_input {
        padding: 7.5px 0 8px 15px;
    }
}