.mainHeader {
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    width: 100%;
    text-align: center;
    z-index: $header-zindex;
    @include font-smoothing;
    height: $header_height;
    border-bottom: 1px solid $border_grey_light;

    [class*="container"] {
        height: 100%;
        position: relative;
    }
}

.nav_bar_wrapper {
    @include flexbox();
    z-index: 1;
    width: 100%;
    height: 100%;
    justify-content: space-between;
}

.navbar_group {
    width: auto;
    height: 100%;
    display: block;
    @include flexbox();
    align-items: center;
    position: relative;
}

.nav_group_left {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    z-index: 5;
    float: left;
    flex-shrink: 0;
}

.nav_group_right {
    float: right;
    justify-content: flex-end;
    z-index: 5;
    flex-shrink: 0;
}

.nav_group_center {
    flex-grow: 1;

    @include MQ-MAX(S) {
        width: 100%;
        position: absolute;
        left: 0;
    }

    @include MQ(L) {
        padding-left: 40px;
    }
}

.brand_img {
    display: block;

    .pane_header & {
        margin-bottom: 30px;
    }

    .mainHeader &{
        height: $header_height;
        float: left;
    }
}

.logo_link {
    padding: 0;
    display: inline-block;
    line-height: $header_height;
    width: 130px;
    position: relative;
    height: 100%;

    @include MQ(M) {
        width: 200px;
    }

    >img {
        vertical-align: middle;
        display: inline-block;
    }
}

.navMenu {
    vertical-align: middle;
    line-height: $header_height;
    @include clearfix;
    color: rgba($brand_black, .7);
    fill: rgba($brand_black, .7);
    @include flexbox();
    align-items: center;

    &.menu_alt {
        height: $header_height;
    }

    .nav_btn {
        background-color: rgba($brand_primary, .15);
        fill: $border_grey_dark;
        color: $border_grey_dark;
        line-height: 21px;
        padding: 10px;
        border-radius: 50%;
        font-weight: 600;
        text-transform: uppercase;
        @include transition(all .4s ease-in-out);

        @include MQ(M) {
            border-radius: 25px;
        }

        &:hover, &:focus {
            background-color: rgba($brand_primary, .35);
            fill: $brand_black;
            color: $brand_black;
        }
    }
}

.menuList-item {
    position: relative;

    &.is_button {
        margin-left: 20px;
    }
}

.menu__primary {
    @include transition(all .6s cubic-bezier(.68, .34, .15, 1));
    transform-origin: top;

    @include MQ-MAX(M) {
        left: 0;
        width: 100%;
        position: fixed;
        height: 100%;
        visibility: hidden;
        top: $header_height;
        flex-direction: column;
        background-color: white;
        border-top: 2px solid $brand_primary;
        @include transform(scaleY(0));
    }

    .is-active & {
        @include transform(scaleY(1));
        visibility: visible;
        @include transition(transform .8s cubic-bezier(.68, .34, .15, 1));
    }

    @include MQ(M) {
        width: auto;
        flex-direction: row;
    }
}

.menu__link_item:not(.btn) {
    display: block;
    padding-left: 15px;
    padding-right: 15px;
    letter-spacing: -0.015em;
    text-transform: uppercase;
    color: inherit;

    .active &,
    &:hover,
    &:focus {
        color: $brand_black;
        box-shadow: inset 0 -3px 0 $brand_primary;
    }

    .active & {
        font-weight: 600;
    }
}

.nav_trigger {
    display: inline-block;
    margin-left: 20px;

    @include MQ(M) {
        display: none;
    }
}

.primary-nav-trigger {
    position: relative;
}

.menu-icon {
    /* this span is the central line of the menu icon */
    display: block;
    width: 24px;
    height: 1px;
    position: relative;
    background-color: $border_grey_dark;
    @include transition(background-color .3s);
    /* these are the upper and lower lines in the menu icon */
    &::before,
    &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: $border_grey_dark;
        right: 0;
        @include transition(transform .3s, top .3s, background-color 0s);
    }
    &::before {
        top: -7px;
    }
    &::after {
        top: 7px;
    }
    &.is-clicked {
        background-color: rgba($brand_primary, 0);
        &::before,
        &::after {
            background-color: rgba($brand_primary, 1);
        }
        &::before {
            top: 0;
            @include transform(rotate(135deg));
        }
        &::after {
            top: 0;
            @include transform(rotate(225deg));
        }
    }
}

// Navbar search
.navbar_search_form {
    background-color: white;
    width: 100%;
    height: $header_height;
    padding: 8px 10px;
    z-index: 1;
    visibility: hidden;
    @include transform(scaleY(0));
    transform-origin: top;
    @include transition(all .6s cubic-bezier(.68, .34, .15, 1));

    .listNavtem &{
        height: 40px;
    }

    .show_search &{
        @include transform(scaleY(1));
        visibility: visible;
        @include transition(transform .6s cubic-bezier(.68, .34, .15, 1));
    }

    @include MQ(S) {
        padding-top: 10px;
        padding-bottom: 10px;
        display: block;
        visibility: visible;
        @include transform(none);
        background: transparent;
        position: relative;
        max-width: 400px;

        .listNavtem & {
            max-width: 100%;
            padding: 0;
        }
    }
}

.navbar_search_input {
    background-color: white;
    border-radius: 40px;
    color:$brand_black;
    border: 1px solid $border_grey_light;
    display: block;
    @include text_truncate();
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0 30px;

    .listNavtem & {
        padding-left: 15px;
    }

    &:focus {
        outline: none;
        border-color: $brand_primary;
    }
}

.navbar_search_btn {
    margin-top: -10px;
    border-radius: 0;
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: auto;
    fill: $border_grey_dark;

    &:hover, &:focus {
        fill: $brand_primary;
    }
}

.navbar_search_submit {
    left: 20px;

    .listNavtem & {
        right: 20px;
        left: auto;
    }
}

.navbar_search_reset {
    right: 13px;
    opacity: 0;
    visibility: hidden;
    @include transition(all .4s ease);

    .has_value &{
        opacity: 1;
        visibility: visible;
    }
}


.control_btn {
    margin-left: 20px;
    position: relative;


    > a:not(.nav_btn) {
        fill: $border_grey_dark;
        color: $border_grey_dark;

        &:hover, &:focus {
            fill: $brand_black;
            color: $brand_black;
        }
    }

    @include MQ(M) {
        margin-left: 40px;
    }
}


.btn_search_trigger {
    @include MQ(M) {
        display: none;
    }
}

.badge_count {
    padding: .5em;
    display: inline-block;
    background-color: $brand_primary;
    color: $color_white;
    font-size:9px;
    line-height: 7.5px;
    border-radius: 25px;
    vertical-align: super;
    margin-left: -8px;

}

.control_label {
    margin-left: 5px;


    @include MQ-MAX(M) {
        display: none;
    }
}

// Forms widget nav

.formWidgetHeader {
    left: 0px;
    position: fixed;
    right: 0px;
    top: 0px;
    width: 100%;
    z-index: 99;
    background-color: white;
    border-bottom: 1px solid $border_grey;
}

.widgetHeaderWrapper {
    @include flexbox();
    height: $header_height;
    left: 0px;
    position: relative;
    top: 0px;
    width: 100%;
    z-index: auto;
    min-height: $header_height;
    -webkit-box-pack: justify;
    justify-content: space-between;
    overflow: visible;
}

.formNavGroup {
    @include flexbox();
    flex: 1 0 auto;
    align-items: center;
}

.navGroupLeft {
    -webkit-box-pack: start;
    justify-content: flex-start;
}

.navGroupCenter {
    flex-grow: 0;
    flex-shrink: 0;
    -webkit-box-pack: center;
    justify-content: center;
}

.navGroupRight {
    -webkit-box-pack: end;
    justify-content: flex-end;
}

.navGroupItem {
    padding-left:10px;
    padding-right:10px;
    @include flexbox();
    text-transform: uppercase;
    height: 100%;
    background-color: transparent;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    @include transition(all 0.4s ease-in-out 0s);
    box-sizing: border-box;
    fill: $border_grey_dark;
    font-weight: 700;

    .navGroupLeft & {
        border-right: 1px solid $border_grey_light;

        &:hover, &:focus {
            background-color: $border_grey_light;
            fill: $brand_primary;
        }
    }

    @include MQ(M) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.formSubmit {
    background-color: $brand_primary;
    color: $color_white;
    font-size: 12px;

    &:hover, &:focus {
        background-color: darken($brand_primary, 5);
    }

    @include MQ(M) {
        padding-right: 45px;
        padding-left: 45px;
    }

    &.disabled, &:disabled {
        background-color: $border_grey_dark;
    }
}

.formProgressBar {
    background-color: transparent;
    height: 2px;
    left: 0px;
    position: absolute;
    right: 0px;
    top: auto;
    z-index: 1;
    bottom: 1px;
}

.perfMeter {
    height: 6px;
    width: 100%;
    min-width: 120px;
}

.progress {
    height: 100%;
    font-size: 0;
    border-radius: 25px;
}

.progress-bar {
    background-color: $brand_primary;
    border-radius: 25px;

    .progress_success &{
        background-color: $brand_accent;
    }
}

.perfTitle {
    font-size: 14px;
    padding-right: 10px;
}