@charset "UTF-8";
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 62.5%;
}

img {
  width: 100%;
  height: auto;
}

mark {
  padding: 0 5px;
  border-radius: 5px;
  background-color: #61f9ac;
}

time {
  white-space: nowrap;
}

body {
  font-family: "Work Sans", Roboto, Segoe UI, Open Sans, sans-serif;
  color: #555555;
  font-size: 1.4rem;
  font-size: 14px;
  word-spacing: 0.05em;
  letter-spacing: 0.0625em;
  height: 100%;
  height: 100vh;
  width: 100%;
  font-weight: 400;
  position: relative;
  line-height: 1.65;
  background-color: white;
}
body.scroll_lock {
  overflow: hidden;
}

.has_brackets {
  font-size: 10px;
  display: inline-block;
  line-height: 21px;
  padding: 0 8px;
  margin-left: 4px;
  font-weight: normal;
  color: #757575;
  background-color: #E5E5E5;
  border-radius: 5px;
  vertical-align: middle;
}

/* Backgrounds */
.bg__default {
  background-color: #E5E5E5 !important;
}

.bg__white {
  background-color: white !important;
}

.bg__off_white {
  background-color: #f5f5f5 !important;
}

.bg__primary {
  background-color: #ff9000 !important;
}

.bg__primary_dark {
  background-color: #EA7700 !important;
}

.bg__secondary {
  background-color: #06A253 !important;
}

.bg__secondary_tinted {
  background-color: #9bdaba !important;
}

.bg__black {
  background-color: #353535 !important;
}

.bg__inverse {
  background-color: #1C59A0 !important;
}

.bg__primary_light {
  background-color: #FEF9F3 !important;
}

.color__primary {
  color: #ff9000 !important;
}

.color__secondary {
  color: #06A253 !important;
}

.color__inverse {
  color: #1C59A0 !important;
}

.color__black {
  color: #353535 !important;
}

.color__grey_light {
  color: #959595 !important;
}

.color__grey_dark {
  color: #757575 !important;
}

.color__white {
  color: #f5f5f5 !important;
}

.color__default {
  color: #555555 !important;
}

.font-default {
  font-size: 14px !important;
}

.font-sm {
  font-size: 12px !important;
}

.font-md {
  font-size: 16px !important;
}

.font-lg {
  font-size: 21px !important;
}

.font-bold {
  font-family: "Work Sans", Roboto, Segoe UI, Open Sans, sans-serif !important;
  font-weight: 700;
}

.font-regular {
  font-family: "Work Sans", Roboto, Segoe UI, Open Sans, sans-serif !important;
  font-weight: 400;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.caps_upper {
  text-transform: uppercase !important;
}

.caps_normal {
  text-transform: none !important;
}

.container {
  position: relative;
  max-width: 1170px;
}

.content_narrow {
  max-width: 960px;
}

.centered {
  float: none;
  margin: 0 auto;
}

.hidden {
  display: none !important;
}

/* Mobile devices */
@media only screen and (max-width: 575px) {
  .hidden-sm {
    display: none !important;
  }
}
/* Tablets and phablets */
@media only screen and (min-width: 768px) {
  .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Work Sans", Roboto, Segoe UI, Open Sans, sans-serif;
  margin: 0;
  letter-spacing: 0.02em;
  font-weight: 700;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 21px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

p {
  margin: 0;
}
p + p {
  padding-top: 0.5em;
}

.centered {
  float: none;
  margin: 0 auto;
}

address,
address span {
  display: block;
}

.close_dialog {
  z-index: 5;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 50%;
  position: absolute;
  right: 15px;
  top: 10px;
  /* image replacement properties */
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  cursor: pointer;
  /* Tablets and phablets */
}
.close_dialog::before, .close_dialog::after {
  position: absolute;
  content: "";
  width: 2px;
  background: #959595;
  height: 14px;
  top: 50%;
  left: 50%;
  margin: -7px 0 0 -1px;
}
.close_dialog::before {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.close_dialog::after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.close_dialog:hover, .close_dialog:focus {
  background-color: rgba(149, 149, 149, 0.2);
}
.close_dialog:hover::before, .close_dialog:hover::after, .close_dialog:focus::before, .close_dialog:focus::after {
  background-color: #353535;
}
@media only screen and (min-width: 768px) {
  .dialog_dismiss .close_dialog {
    width: 50px;
    height: 50px;
  }
  .dialog_dismiss .close_dialog::before, .dialog_dismiss .close_dialog::after {
    height: 25px;
    margin: -12.5px 0 0 -1px;
  }
}
.item_dismiss .close_dialog {
  position: relative;
  top: 3px;
  left: 0;
  right: auto;
}

.badge {
  font-size: 11px;
  border-radius: 5px;
  padding: 0.65em 1.4em;
  background-color: #d5d5d5;
  color: inherit;
  display: inline-block;
  text-align: center;
  font-weight: 700;
}

.badge-primary {
  background-color: rgba(255, 144, 0, 0.15);
  color: #ff9000;
}

.badge-secondary {
  background-color: rgba(6, 162, 83, 0.15);
  color: #06A253;
}

.badge-danger {
  background-color: rgba(210, 31, 60, 0.15);
  color: #D21F3C;
}

.badge-black {
  background-color: rgba(53, 53, 53, 0.15);
  color: #353535;
}

mark {
  padding: 0.4em 1em;
  background-color: rgba(255, 144, 0, 0.15);
  color: #ff9000;
  font-weight: 700;
}

.status_tag {
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #959595;
  white-space: nowrap;
}

.status_tag-primary {
  color: #D21F3C;
}

.status_tag-secondary {
  color: #00A86B;
}

.tag_item {
  color: #555555;
  font-size: 11px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5f5;
  border-radius: 20px;
  overflow: hidden;
}
.tag_item:focus, .tag_item:active, .tag_item:hover {
  outline: none;
}
.tag_item:focus .tag_ico, .tag_item:active .tag_ico, .tag_item:hover .tag_ico {
  opacity: 0.6;
}

.tag_color {
  width: 25px;
  height: 25px;
  border-radius: 20px 0 0 20px;
  margin-left: -4px;
  background-color: #959595;
}

.tag_empty {
  background-color: transparent;
  border: 1px solid #959595;
  color: #555555 !important;
}

.tag_label {
  line-height: 25px;
  padding: 0 20px 0 10px;
}

.tag_ico {
  justify-self: flex-end;
  opacity: 0.15;
  -webkit-transition: opacity 0.4s linear;
  -moz-transition: opacity 0.4s linear;
  -o-transition: opacity 0.4s linear;
  transition: opacity 0.4s linear;
}

.tag_yellow {
  background-color: #ffd300;
  color: #353535 !important;
}

.tag_red {
  background-color: #D21F3C;
}

.tag_green {
  background-color: #00A86B;
}

.tag_purple {
  background-color: #702963;
}

.tag_default {
  background-color: #757575;
}

.tag_black {
  background-color: #353535;
}

.tag_blue {
  background-color: #008ECC;
}

.tag_pink {
  background-color: #EC5578;
}

.tag_like {
  background-color: #1c2951;
}

.tag_orange {
  background-color: #ff9000;
}

.no-padding-top {
  padding-top: 0 !important;
}

.margin_top_sm {
  margin-top: 10px !important;
}

.margin_top_md {
  margin-top: 20px !important;
}

.margin_top_lg {
  margin-top: 30px !important;
}

.margin_top_xl {
  margin-top: 50px !important;
}

.margin_bottom_sm {
  margin-bottom: 10px !important;
}

.margin_bottom_md {
  margin-bottom: 20px !important;
}

.margin_left_sm {
  display: inline-block;
  margin-left: 5px;
}

.margin_left_s {
  margin-left: 10px;
}

.empty_state {
  position: relative;
  padding: 40px 0;
  max-width: 720px;
}
.empty_state .def_wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.def_img {
  width: 128px;
  margin: 20px auto;
  clear: both;
}

.alert {
  display: block;
  padding: 20px;
  border-radius: 5px;
  background-color: #E5E5E5;
  margin-bottom: 30px;
}
.alert a {
  font-weight: 700;
  color: #353535;
}
.alert a:hover {
  text-decoration: underline;
}

.alert_scondary {
  background-color: rgba(6, 162, 83, 0.15);
}

.alert_error {
  background-color: rgba(210, 31, 60, 0.15);
}

.alert_primary {
  background-color: rgba(255, 144, 0, 0.15);
}

.extra_info {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
}

.alert_icon {
  flex-shrink: 0;
  margin-right: 10px;
}
.alert_icon + .alert_text {
  flex: 1;
}

.alert_info {
  flex: 1;
}

.alert-dismissible .close {
  top: 15px;
}

.icon_box {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  background-color: rgba(53, 53, 53, 0.15);
  color: #353535;
  fill: #353535;
  margin-right: 10px;
}

.box_rounded {
  border-radius: 50% !important;
}

.box_primary {
  background-color: rgba(255, 144, 0, 0.15);
  color: #ff9000;
  fill: #ff9000;
}

.box_secondary {
  background-color: rgba(6, 162, 83, 0.15);
  color: #06A253;
  fill: #06A253;
}

.box_inverse {
  background-color: rgba(28, 89, 160, 0.15);
  color: #1C59A0;
  fill: #1C59A0;
}

.base_divider {
  height: 5px;
  border-top: 1px solid #d5d5d5;
  border-bottom: 1px solid #d5d5d5;
  margin: 20px 0;
}

.chart_legend {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin: 0 -15px;
}

.legend_ind {
  margin-right: 7.5px;
}

.legend_item {
  padding: 10px 15px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}

.legend_label {
  line-height: 21px;
}

/*Loader widget*/
.page_loader {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(3, 33, 29, 0.95);
  display: none;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0.6s ease-in-out, opacity 1s ease-in-out;
  -moz-transition: visibility 0.6s ease-in-out, opacity 1s ease-in-out;
  -o-transition: visibility 0.6s ease-in-out, opacity 1s ease-in-out;
  transition: visibility 0.6s ease-in-out, opacity 1s ease-in-out;
}
.page_loader.loading {
  display: block;
  visibility: visible;
  opacity: 1;
  z-index: 2000;
  -webkit-transition: visibility 0.6s ease-in-out, opacity 1s ease-in-out;
  -moz-transition: visibility 0.6s ease-in-out, opacity 1s ease-in-out;
  -o-transition: visibility 0.6s ease-in-out, opacity 1s ease-in-out;
  transition: visibility 0.6s ease-in-out, opacity 1s ease-in-out;
}

.loader_content {
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  font-size: 16px;
  text-align: center;
  max-width: 300px;
  color: #E5E5E5;
}

.loaderImage {
  max-width: 64px;
  margin: 10px auto 20px;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #959595;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #959595;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #959595;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #959595;
}

fieldset {
  padding: 20px 0;
}
fieldset + fieldset {
  border-top: 1px solid #d5d5d5;
}
fieldset > p {
  margin-bottom: 8.5px;
}

.input-group {
  z-index: 1;
}
.input-group + .input-group {
  margin-top: 1em;
}

.input-group-addon {
  background: #353535;
  color: #E5E5E5;
  font-size: 0.923em;
  padding: 0.75em 1em;
  align-self: center;
  height: 100%;
  border-radius: 0 5px 5px 0;
}

.input-group-btn {
  font-size: 1em;
}
.input-group-btn .btn {
  vertical-align: top;
  padding-right: 1.5em;
  padding-left: 1.5em;
}

.form-horizontal {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .form-horizontal {
    margin-bottom: 20px;
  }
}

.slim_form {
  max-width: 500px;
  clear: both;
}

.form_row_group {
  margin-bottom: 20px;
  position: relative;
}

.form_group_item {
  position: relative;
}

/* Mobile devices */
@media only screen and (min-width: 576px) {
  .text_right_sm {
    text-align: right;
  }
}
.form_row_title {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: normal;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .form_row_title {
    margin-top: 10px;
    margin-right: 20px;
  }
}

.form-group {
  position: relative;
  margin-bottom: 20px;
}
.form-group .checkbox-inline {
  margin-top: 0;
}
.form-group > a:not(.btn) {
  display: inline-block;
  margin-top: 10px;
}

input:focus {
  outline: none;
}

.help-block {
  font-size: 0.85em;
  color: #757575;
  font-style: italic;
  margin: 7.5px 0;
}

.form-control {
  padding: 8px 12px;
  font-size: 14px;
  line-height: normal;
  top: 0;
  left: 0;
  z-index: 1;
  margin: 0;
  outline: 0;
  width: 100%;
  min-height: 40px;
  position: relative;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  overflow: hidden;
  color: #555555;
  text-indent: 0.01px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-shadow: 0 0 0, transparent;
  -moz-box-shadow: 0 0 0, transparent;
  box-shadow: 0 0 0, transparent;
  vertical-align: middle;
}
.form-control.width_auto {
  width: auto;
}
.form-control.title_input {
  font-size: 18px;
  padding: 12px 16px;
}
.form-control.field_rounded {
  border-radius: 25px;
  padding: 15px 20px;
}
.messageInputControl .form-control {
  border: none;
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
}
.form-control:focus {
  outline: 0;
  border-color: #ff9000;
  box-shadow: none;
}
.form-control:disabled, .form-control[readonly] {
  border: none;
}

textarea {
  box-sizing: border-box;
  vertical-align: middle;
  cursor: text;
  resize: none;
}

.prefixed_input {
  padding-left: 40px;
}

.form_mng {
  max-width: 450px;
}

.date_selector_widget {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.date_selector_widget + .date_selector_widget {
  margin-top: 20px;
}

.date_item {
  width: 120px;
  position: relative;
}
.date_item:not(:last-of-type) {
  margin-right: 15px;
}

.date_month {
  width: auto;
  flex-grow: 1;
}

select.form-control {
  background: white url(/assets/img/icons/arrow-down.png) right center no-repeat;
  padding-right: 25px;
}
select.form-control:focus {
  outline: none;
  background: white url(/assets/img/icons/arrow-down.png) right center no-repeat;
}

.form_link {
  color: #ff9000;
  font-weight: 600;
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
  display: block;
}
.form_link:hover, .form_link:hover {
  opacity: 0.9;
  text-decoration: underline;
}

.radio,
.checkbox {
  line-height: 1;
}
.radio + .radio,
.radio + .checkbox,
.checkbox + .radio,
.checkbox + .checkbox {
  margin-top: 15px;
}

input[type=radio],
.radio input[type=radio],
.radio-inline input[type=radio] {
  position: relative;
  margin-top: 0;
  margin-right: 7.5px;
  vertical-align: top;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
input[type=radio]:focus,
.radio input[type=radio]:focus,
.radio-inline input[type=radio]:focus {
  outline: none;
}
input[type=radio]:before, input[type=radio]:after,
.radio input[type=radio]:before,
.radio input[type=radio]:after,
.radio-inline input[type=radio]:before,
.radio-inline input[type=radio]:after {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  top: 3px;
  -webkit-transition: 240ms;
  -moz-transition: 240ms;
  -o-transition: 240ms;
  transition: 240ms;
}
.control_flex input[type=radio]:before, .control_flex input[type=radio]:after,
.control_flex .radio input[type=radio]:before,
.control_flex .radio input[type=radio]:after,
.control_flex .radio-inline input[type=radio]:before,
.control_flex .radio-inline input[type=radio]:after {
  top: 0;
}
input[type=radio]:before,
.radio input[type=radio]:before,
.radio-inline input[type=radio]:before {
  position: absolute;
  left: 0;
  background-color: #ff9000;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
input[type=radio]:after,
.radio input[type=radio]:after,
.radio-inline input[type=radio]:after {
  position: relative;
  border: 1px solid #757575;
}
input[type=radio]:checked:before,
.radio input[type=radio]:checked:before,
.radio-inline input[type=radio]:checked:before {
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
}
input[type=radio]:disabled:checked:before,
.radio input[type=radio]:disabled:checked:before,
.radio-inline input[type=radio]:disabled:checked:before {
  background-color: #959595;
}
input[type=radio]:checked:after,
.radio input[type=radio]:checked:after,
.radio-inline input[type=radio]:checked:after {
  border-color: #ff9000;
}
input[type=radio]:disabled:after, input[type=radio]:disabled:checked:after,
.radio input[type=radio]:disabled:after,
.radio input[type=radio]:disabled:checked:after,
.radio-inline input[type=radio]:disabled:after,
.radio-inline input[type=radio]:disabled:checked:after {
  border-color: #959595;
}

input[type=checkbox],
.checkbox input[type=checkbox],
.checkbox-inline input[type=checkbox] {
  position: relative;
  border: none;
  margin-bottom: -4px;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
}
input[type=checkbox]:focus,
.checkbox input[type=checkbox]:focus,
.checkbox-inline input[type=checkbox]:focus {
  outline: none;
}
input[type=checkbox]:after,
.checkbox input[type=checkbox]:after,
.checkbox-inline input[type=checkbox]:after {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  margin-top: -2px;
  margin-right: 7.5px;
  border: 1px solid #353535;
  border-radius: 3px;
  -webkit-transition: 240ms;
  -moz-transition: 240ms;
  -o-transition: 240ms;
  transition: 240ms;
}
input[type=checkbox]:checked:before,
.checkbox input[type=checkbox]:checked:before,
.checkbox-inline input[type=checkbox]:checked:before {
  content: "";
  position: absolute;
  top: 0;
  left: 6px;
  display: table;
  width: 6px;
  height: 12px;
  border: 1px solid #f5f5f5;
  border-top-width: 0;
  border-left-width: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
input[type=checkbox]:checked:after,
.checkbox input[type=checkbox]:checked:after,
.checkbox-inline input[type=checkbox]:checked:after {
  background-color: #ff9000;
  border-color: #ff9000;
}
input[type=checkbox]:disabled:after,
.checkbox input[type=checkbox]:disabled:after,
.checkbox-inline input[type=checkbox]:disabled:after {
  border-color: #959595;
}
input[type=checkbox]:disabled:checked:after,
.checkbox input[type=checkbox]:disabled:checked:after,
.checkbox-inline input[type=checkbox]:disabled:checked:after {
  background-color: #E5E5E5;
  border-color: #d5d5d5;
}
input[type=checkbox]:disabled:checked:before,
.checkbox input[type=checkbox]:disabled:checked:before,
.checkbox-inline input[type=checkbox]:disabled:checked:before {
  border-color: #959595;
}

input[type=radio],
input[type=checkbox] {
  margin-top: 0;
}

.control-label {
  font-size: 1.4rem;
  display: inline-block;
  color: #959595;
  letter-spacing: 0.1rem;
  margin-bottom: 0;
  padding-bottom: 7.5px;
  cursor: pointer;
}
[class*=checkbox] .control-label, [class*=radio] .control-label {
  color: inherit;
}

.required::after {
  content: "*";
  font-size: 1.15em;
  padding-left: 4px;
  color: red;
}

.radio-inline,
.checkbox-inline {
  padding: 10px 0 5px;
}
.form-row .radio-inline,
.form-row .checkbox-inline {
  padding: 0;
}

label {
  font-weight: normal;
}
label.radio-inline + label {
  margin-left: 15px;
}
label.checkbox-inline {
  margin: 5px 0;
  margin-bottom: 0 !important;
  font-size: 14px;
  display: inline-block;
  vertical-align: top;
}

.form_body {
  display: block;
  max-width: 720px;
  padding-top: 30px;
}

.control_flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 15px;
  background-color: transparent;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  -webkit-transition: background-color 0.4s;
  -moz-transition: background-color 0.4s;
  -o-transition: background-color 0.4s;
  transition: background-color 0.4s;
  cursor: pointer;
  text-align: left;
}
.control_flex + .control_flex {
  margin-top: 30px;
}
.control_flex:hover, .control_flex:focus, .control_flex.active {
  background-color: rgba(255, 144, 0, 0.15);
  color: inherit;
  border-color: rgba(255, 144, 0, 0.5);
}

.form_ind {
  flex-shrink: 0;
}

.form_info_block {
  flex-grow: 1;
  font-size: 14px;
  line-height: 1.65;
  margin-top: -7.5px;
  padding-left: 7.5px;
}
.form_info_block > span {
  display: block;
}

.label_title {
  font-size: 14px;
  margin-bottom: 5px;
  line-height: 24px;
  font-weight: 600;
  color: #353535;
}

.label_text {
  font-size: 14px;
  line-height: 21px;
}

.form_cto {
  display: block;
  clear: both;
  margin-top: 40px;
  padding-top: 20px;
  border-top: 2px solid #d5d5d5;
}

.profile_image_thumb {
  width: 200px;
  height: 200px;
  margin-bottom: 50px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}

.avi_upload_button {
  background-color: rgba(245, 245, 245, 0.85);
  fill: #353535;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  padding: 12px 15px;
  margin: 0;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.avi_upload_button:hover {
  background-color: #ff9000;
  fill: #f5f5f5;
}

.button_docked {
  line-height: 1;
  width: 100%;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
}

.input_control {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
}
.input_control:hover, .input_control:focus {
  color: #ff9000;
}
.input_control:hover .t_icon, .input_control:focus .t_icon {
  border-color: #ff9000;
  fill: #ff9000;
}

.t_icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px dashed #959595;
  flex-shrink: 0;
  margin-right: 10px;
  fill: #959595;
  align-items: center;
  justify-content: center;
  display: flex;
}
.t_icon svg {
  width: 12px;
  height: 12px;
}

/*Loader*/
@keyframes spinner_spin {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}
.cm_spinner {
  display: inline-block;
  position: absolute;
}
.has_loader .cm_spinner {
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.form-group .cm_spinner {
  right: 7px;
  top: 35px;
}
.cm_spinner::after {
  content: "";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 5px;
  box-sizing: border-box;
  border: 10px solid #1C59A0;
  border-color: #ff9000 transparent #353535 transparent;
  animation: spinner_spin 2.4s infinite;
}

.loading {
  overflow: hidden;
}

.loader_item {
  z-index: 10;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.has_loader .loader_item {
  background-color: rgba(255, 255, 255, 0.85);
}
.loading .loader_item {
  visibility: visible;
  opacity: 1;
}

.filter_navigation {
  display: block;
  margin-bottom: 30px;
}

.filter_menu,
.filter_search {
  padding: 7.5px 0;
}

.filter_nav_options {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  overflow-x: auto;
  margin: 0 -10px;
}
.filter_nav_options li {
  padding: 5px 10px;
}
.filter_nav_options a {
  font-weight: 700;
  font-size: 12px;
  display: block;
  padding: 7px 21px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  border-radius: 40px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.filter_nav_options a.active, .filter_nav_options a:hover, .filter_nav_options a:focus {
  color: #353535;
  background-color: #E5E5E5;
}

.filter_search {
  border-top: 1px solid #d5d5d5;
  border-bottom: 1px solid #d5d5d5;
}

.search_bar_holder {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.search_btn {
  flex-shrink: 0;
  margin-right: 15px;
}
.search_btn .cust_icon {
  stroke: #ff9000;
  stroke-width: 2;
}

.search_input, .linearInput {
  flex: 1;
  width: 100%;
  position: relative;
  margin: 0;
}

.filter_search_input, .linearInputform {
  width: 100%;
  display: block;
  border: none;
  outline: 0;
  background-color: transparent;
}

.has_suggestion {
  position: relative;
}

.search_suggestion_widget {
  z-index: 3000;
  width: 100%;
  top: 42px;
  left: 0px;
  position: absolute;
  display: none;
}
.has_suggestion .search_suggestion_widget {
  display: block;
}

.widget_wrapper {
  background-color: white;
  border: 1px solid #E5E5E5;
  border-radius: 5px;
  box-shadow: 1px 1px 4px rgba(53, 53, 53, 0.15);
  width: 100%;
  height: auto;
}

.search_result_list {
  clear: both;
  margin: 0;
  padding: 5px 0;
}
.no_match .search_result_list {
  display: none;
}

.search_result_empty, .search_result_item, .notificationItem {
  position: relative;
  width: 100%;
}

.search_result_item {
  list-style-type: none;
  cursor: pointer;
  font-size: 14px;
}

.result_item_wrapper {
  display: block;
  width: 100%;
  height: 100%;
  padding: 5px 15px;
  background-color: transparent;
}
.result_item_wrapper.docWrapper {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 5px;
}
.result_item_wrapper:hover, .result_item_wrapper:focus {
  color: inherit;
  background-color: #f5f5f5;
}
.active .result_item_wrapper {
  background-color: rgba(255, 144, 0, 0.15);
}

.search_result_empty {
  display: none;
  padding: 10px 15px;
}
.no_match .search_result_empty {
  display: block;
}

.user_result_block {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  height: 35px;
  line-height: 35px;
}

.item_result_block {
  padding-top: 5px;
  padding-bottom: 5px;
}

.result_display_name {
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.search_result_avi {
  margin-right: 10px;
  font-size: 10px;
}

.upload_dialog {
  clear: both;
  width: 100%;
  margin-bottom: 50px;
}

.upload_control {
  border: 1px dashed #d5d5d5;
  background-color: #E5E5E5;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  padding: 30px;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  fill: #959595;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.upload_control:hover, .upload_control:focus {
  fill: #353535;
  border-color: #ff9000;
  background-color: #FEF9F3;
}

.upload_message {
  margin-top: 15px;
}

.upload_item {
  padding: 10px;
  background-color: white;
  border: 1px solid #E5E5E5;
  border-radius: 5px;
  box-shadow: 1px 0 3px #d5d5d5;
  position: relative;
  margin-bottom: 10px;
}

.file_item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  width: 100%;
}

.file_description, .fileUploadProgress {
  clear: both;
  margin-top: 10px;
  width: 100%;
}

.fileUploadProgress {
  height: 5px;
}

.mailFormItem {
  position: relative;
  margin-bottom: 15px;
}

.linearInputform {
  padding-left: 70px;
  box-shadow: inset 0 -1px 0 #d5d5d5;
  padding-bottom: 8px;
  padding-top: 8px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.linearInputform:focus {
  box-shadow: inset 0 -2px 0 #ff9000;
}

.messageTextarea {
  padding: 10px 0;
  width: 100%;
  display: block;
  border: none;
  outline: 0;
  background-color: transparent;
}

.formLabel {
  position: absolute;
  top: 10px;
  left: 0;
  font-size: 14px;
  font-weight: 600;
  color: #757575;
}

.listSearchFilter {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filterSearchControl, .drawerSearch {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  border: 1px solid #E5E5E5;
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
}

.SearchInput {
  border: none;
  padding: 0;
  background-color: transparent;
  flex-grow: 1;
  padding: 10px 12px;
}

.filterPermission {
  flex-shrink: 0;
  padding-left: 10px;
}

.filterSearchAction {
  flex-shrink: 0;
  margin-left: 10px;
}

.drawerSearch {
  background-color: #f5f5f5;
  border: 1px solid #d5d5d5;
  border-radius: 30px;
}
.drawerSearch .filter_search_input {
  padding: 7.5px 0 8px 15px;
}

/* SVG Icon systems */
.cust_icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle;
  background-size: contain;
  fill: inherit;
}
.icon_holder .cust_icon {
  width: 30px;
  height: 30px;
}
.cust_icon.tag_ico {
  width: 10px;
  height: 10px;
}

svg {
  display: inline-block;
  width: 100%;
}

.icon_xs {
  width: 16px;
  height: 16px;
}

.icon_md {
  width: 48px;
  height: 48px;
}

.icon_lg {
  width: 64px;
  height: 64px;
}

.icon_password {
  background-image: url(/assets/img/icons/password.svg);
}

.icon_off {
  background-image: url(/assets/img/icons/switch_off.svg);
}

.icon_info {
  background-image: url(/assets/img/icons/info.svg);
}

.icon_edit {
  background-image: url(/assets/img/icons/edit-pencil.svg);
}

.icon_sample {
  background-image: url(/assets/img/icons/file-text.svg);
}

.icon_performance {
  background-image: url(/assets/img/icons/performance.svg);
}

.icon_close {
  background-image: url(/assets/img/icons/close_icon.svg);
}

.icon_email {
  background-image: url(/assets/img/icons/envelope.svg);
}

.icon_phone {
  background-image: url(/assets/img/icons/phone.svg);
}

.icon_address {
  background-image: url(/assets/img/icons/map.svg);
}

.icon_tasks {
  background-image: url(/assets/img/icons/list-add.svg);
}

.icon_download {
  background-image: url(/assets/img/icons/download.svg);
}

.icon_url {
  background-image: url(/assets/img/icons/link.svg);
}

.icon_new_file {
  background-image: url(/assets/img/icons/new_file.svg);
}

.icon_new_doc {
  background-image: url(/assets/img/icons/new_doc.svg);
}

.icon_new_folder {
  background-image: url(/assets/img/icons/new_folder.svg);
}

.icon_briefcase {
  background-image: url(/assets/img/icons/briefcase.svg);
}

.icon_attachment {
  background-image: url(/assets/img/icons/attachment.svg);
}

.icon_folder {
  background-image: url(/assets/img/icons/folder.svg);
}

.icon_members {
  background-image: url(/assets/img/icons/members.svg);
}

.currency {
  font-style: normal;
}
.currency::before {
  font-size: 0.7em;
  color: inherit;
  font-family: "Work Sans", Roboto, Segoe UI, Open Sans, sans-serif;
  vertical-align: text-top;
  margin-right: -5px;
}

.naira::before {
  content: "₦";
}

.media_block {
  margin-top: 10px;
}

.media_block_item {
  width: 100%;
  max-width: 120px;
  padding: 5px 0;
}

.tile_image {
  width: 100%;
  position: relative;
  margin-bottom: 10px;
}

.image_wrapper {
  background-color: #E5E5E5;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  border-radius: 5px;
  align-self: center;
  text-align: center;
  position: relative;
  padding: 0 0 130%;
  overflow: hidden;
  margin: 0 auto;
  display: block;
  width: 100%;
}
.attachment_tile .image_wrapper {
  padding-bottom: 25%;
}
.image_wrapper img {
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.upload_btn {
  cursor: pointer;
  fill: #959595;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #d5d5d5;
  border-radius: 5px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.image_wrapper .upload_btn {
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  z-index: 100;
  opacity: 0.75;
}
.upload_btn:hover, .upload_btn:focus {
  background-color: rgba(255, 144, 0, 0.5);
  opacity: 1;
  fill: #353535;
}

.attachment_tray {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.attachment_tile {
  width: 140px;
}

.tray_item {
  margin-right: 15px;
}

.attachment_dismiss {
  position: absolute;
  z-index: 10;
  top: -10px;
  right: -10px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: white;
  border-radius: 50%;
  color: #959595;
  font-size: 12px;
  font-family: sans-serif;
  font-weight: 700;
  border: 1px solid #d5d5d5;
}
.attachment_dismiss.attach_bottom {
  top: auto;
  bottom: -10px;
}
.attachment_dismiss .cust_icon {
  width: 10px;
  height: 10px;
}
.attachment_dismiss:hover, .attachment_dismiss:focus {
  background-color: #ff9000;
  color: #f5f5f5;
}

.dialog_dismiss {
  position: absolute;
  top: -30px;
  right: 40px;
}

.media_item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  overflow: hidden;
}

.media_figure {
  position: relative;
  flex-shrink: 0;
  margin-right: 10px;
}

.media_info {
  flex: 1;
}

/*Custom Selectors*/
.custom_selector {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.selector_item {
  padding: 5px 7.5px;
}

.cst_selector {
  visibility: hidden;
  opacity: 0;
  width: 0;
  height: 0;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.pill_wrapper {
  position: relative;
  cursor: pointer;
  border: 1px solid #d5d5d5;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  padding: 0.45em 1.8em;
  text-align: center;
  border-radius: 40px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.pill_wrapper:hover, .pill_wrapper:focus, .selected .pill_wrapper {
  background-color: #353535;
  border-color: transparent;
  color: #f5f5f5;
}

.objectWrapper {
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px 15px;
  position: relative;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 10px;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  box-shadow: 1px 1px 3px rgba(53, 53, 53, 0.3);
}
.new_card .objectWrapper {
  box-shadow: unset;
  border: 2px dashed #d5d5d5;
  background-color: transparent;
  justify-content: center;
  align-items: center;
}
.objectWrapper:hover, .objectWrapper:focus {
  background-color: #FEF9F3;
}
.selected .objectWrapper {
  background-color: rgba(255, 144, 0, 0.1);
}

.new_item {
  font-size: 1.6rem;
  display: block;
  letter-spacing: 0.1rem;
  font-family: "Work Sans", Roboto, Segoe UI, Open Sans, sans-serif;
}

.selectInput {
  position: absolute;
  top: 15px;
  right: 10px;
}

.selector_label {
  color: inherit;
  font-size: 13px;
  display: block;
  line-height: 30px;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.object_card {
  width: 100%;
  /* Mobile devices */
  /* Tablets and phablets */
}
@media only screen and (min-width: 576px) {
  .object_card {
    width: 50%;
  }
}
@media only screen and (min-width: 768px) {
  .object_card {
    width: 33.33%;
  }
}

.color_tile {
  width: 33.33%;
  height: 50px;
}

.color_wrapper {
  display: block;
  width: 100%;
  height: 100%;
  padding: 5px 7.5px;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  color: #f5f5f5;
}
.color_wrapper:hover, .color_wrapper:focus {
  opacity: 0.85;
}

.select_indicator {
  position: absolute;
  right: 5px;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  visibility: hidden;
  opacity: 0;
  border-radius: 50%;
  color: inherit;
  color: #f5f5f5;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.select_indicator::after {
  content: "✔";
}
.selected .select_indicator {
  visibility: visible;
  opacity: 1;
}

.detail_control {
  display: block;
  clear: both;
  margin-bottom: 20px;
}

.back_btn {
  font-size: 14px;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  letter-spacing: -0.05em;
}
.back_btn:hover, .back_btn:focus {
  text-decoration: none;
  color: #ff9000;
  opacity: 0.75;
  cursor: pointer;
}

.back_icon {
  margin-right: 5px;
  width: 24px;
  flex-shrink: 0;
}
.back_icon img {
  display: inline-block;
}

.more_control {
  display: block;
  clear: both;
}
.form-control + .more_control {
  margin-top: 10px;
}

.t_icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #d5d5d5;
  flex-shrink: 0;
  margin-right: 10px;
  fill: #ff9000;
  align-items: center;
  justify-content: center;
  display: flex;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.t_icon svg {
  width: 12px;
  height: 12px;
}
.new_card .t_icon {
  width: 48px;
  height: 48px;
}
.new_card .t_icon svg {
  width: 24px;
  height: 24px;
}

.input_control {
  font-size: 1.4rem;
  display: inline-flex;
  align-items: center;
  padding-bottom: 2px;
  border-bottom: 1px dotted transparent;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
}
.input_control:hover, .input_control:focus {
  color: #ff9000;
}
.input_control:hover .t_icon, .input_control:focus .t_icon {
  border-color: #ff9000;
}

.countdown_timer_block {
  clear: both;
  width: 100%;
}

.countdown {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  text-transform: uppercase;
  display: flex;
  line-height: 1;
  align-items: center;
  justify-content: center;
}

.time_entity {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  letter-spacing: 0.015em;
  text-align: center;
  justify-content: center;
}

.timer_entity_seperator {
  font-size: 21px;
  color: #959595;
  padding: 0 10px;
}

.countdown-heading {
  font-size: 11px;
  color: #757575;
  margin-bottom: 5px;
}

.countdown-value {
  font-size: 24px;
  font-weight: 700;
  color: #353535;
}

.calendar_block {
  width: 100%;
  height: 100vh;
}

.calControlGroup {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.calBtnGroup {
  border: 1px solid #d5d5d5;
  border-radius: 25px;
  overflow: hidden;
  display: flex;
}

.calBtn {
  display: flex;
  text-align: center;
  padding: 7.5px 15px;
  font-size: 11px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.calBtn + .calBtn {
  border-left: 1px solid #d5d5d5;
}
.calBtn:hover, .calBtn:focus {
  background-color: #353535;
  fill: #f5f5f5;
  color: #f5f5f5;
}
.calBtn.active {
  background-color: #353535;
  color: #f5f5f5;
}

/* Mobile devices */
@media only screen and (min-width: 576px) {
  .calControlButtons {
    justify-content: flex-end;
  }
}
.iconBtn {
  justify-content: center;
  align-items: center;
}

.calControlHeader {
  margin-bottom: 20px;
}

.calInfoItem {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.calInfoItem + .calInfoItem {
  margin-left: 15px;
}

.cal-month-view .cal-day-badge {
  background-color: #03211D;
}
.cal-month-view .cal-header {
  font-weight: 400;
  font-size: 13px;
  text-transform: uppercase;
}
.cal-month-view .cal-day-number {
  opacity: 0.85;
}
.cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
  opacity: 0.35;
}
.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
  color: #1C59A0;
}
.cal-month-view .cal-day-cell.cal-today {
  background-color: rgba(255, 144, 0, 0.1);
}
.cal-month-view .cal-open-day-events {
  background-color: #03211D;
}

.cal-week-view .cal-event {
  border-color: transparent;
  color: #f5f5f5;
}
.cal-week-view .cal-header.cal-today {
  background-color: rgba(255, 144, 0, 0.1);
}

.cal-day-view .cal-event {
  color: #f5f5f5;
}

.actionTile {
  width: 100%;
  display: block;
}
.actionTile:not(:last-of-type) {
  margin-bottom: 10px;
}

.actionTileWrapper {
  text-align: left;
  padding: 10px 15px;
  line-height: 1.5;
  border-radius: 4px;
  background-color: transparent;
  -webkit-transition: background-color 0.4s ease-in-out;
  -moz-transition: background-color 0.4s ease-in-out;
  -o-transition: background-color 0.4s ease-in-out;
  transition: background-color 0.4s ease-in-out;
}
.actionTileWrapper:hover, .actionTileWrapper:focus {
  background-color: rgba(255, 144, 0, 0.1);
}

.pageFilterBar {
  clear: both;
  margin-bottom: 15px;
}

.filterOption {
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .filterOption {
    min-width: 150px;
  }
}
.filterOption + .filterOption {
  border-left: 1px solid #E5E5E5;
}

.optionsearch {
  flex: 2 0 auto;
}

.filterOptionsPanel {
  background-color: white;
  border: 1px solid #E5E5E5;
}

.filterOptionPanelHeader {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.filterOptionView {
  padding: 15px;
  border-top: 1px solid #E5E5E5;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .filterOptionView {
    padding: 15px 30px;
  }
}

.filterOptionWrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 15px;
  fill: #959595;
  color: #757575;
  position: relative;
}
.filterOptionWrapper:hover, .filterOptionWrapper:focus {
  color: #353535;
  fill: #ff9000;
}
.filterOptionWrapper:active {
  outline: none;
}

.optionIcon + .optionLabel {
  margin-left: 7.5px;
}

.optionLabel {
  flex-grow: 1;
  /* Tablets and phablets */
}
@media only screen and (max-width: 767px) {
  .optionLabel {
    display: none;
  }
}

.viewToggle {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin: 0;
}

.viewToggleItem {
  padding: 0 7.5px;
}

.viewToggleItemLink {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: transparent;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.viewToggleItemLink:hover, .viewToggleItemLink:focus {
  background-color: #E5E5E5;
  fill: #959595;
}
.viewToggleItemLink.active {
  background-color: #03211D;
  fill: #f5f5f5;
}

.filterSearchBar {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.filterParamBlock {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.paramsHeading {
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 15px;
  padding-bottom: 5px;
  border-bottom: 1px solid #E5E5E5;
  color: #757575;
}

.collapsibleBlock {
  display: block;
  -webkit-transition: height 0.6s ease-in-out;
  -moz-transition: height 0.6s ease-in-out;
  -o-transition: height 0.6s ease-in-out;
  transition: height 0.6s ease-in-out;
}
.collapsibleBlock > p {
  line-height: 1.5em;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.collapsibleBlock.blockCollapsed > p {
  height: 6em;
}

.collapseInner {
  height: auto;
  overflow: hidden;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
}
.blockCollapsed .collapseInner {
  height: 0;
  display: none;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility 0.6s ease-in-out, opacity 0.6s ease-in-out, height 0.6s ease-in-out;
  -moz-transition: visibility 0.6s ease-in-out, opacity 0.6s ease-in-out, height 0.6s ease-in-out;
  -o-transition: visibility 0.6s ease-in-out, opacity 0.6s ease-in-out, height 0.6s ease-in-out;
  transition: visibility 0.6s ease-in-out, opacity 0.6s ease-in-out, height 0.6s ease-in-out;
}

.collapsetoggle {
  clear: both;
  display: block;
  margin-top: 7.5px;
  color: #1C59A0;
  width: 100%;
}
.collapsetoggle:hover, .collapsetoggle:focus {
  text-decoration: underline;
  color: #06A253;
}
.collapsetoggle::after {
  content: "Show Less";
  display: inline-block;
}
.blockCollapsed .collapsetoggle::after {
  content: "Show More";
}

.expandCollapseSm {
  text-align: center;
  margin: 10px auto 0;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .expandCollapseSm {
    display: none;
  }
}

.dropdown-menu {
  padding: 0;
  font-size: 1em;
  box-sizing: border-box;
  visibility: hidden;
  z-index: 1000;
  max-width: 460px;
  padding: 8px 0;
  border-radius: 5px;
  color: #959595;
  background-color: white;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
  border: none;
}
.btn-block + .dropdown-menu {
  width: 100%;
}
.dropdown-menu.show {
  visibility: visible;
}
.dropdown-menu li {
  display: block;
  padding: 0;
  margin: 0;
}
.dropdown-menu li > *, .dropdown-menu li > a {
  padding: 10px 15px;
  display: flex;
  width: 100%;
  font-size: 14px;
  color: #555555;
  text-transform: capitalize;
  line-height: normal;
  align-items: center;
  white-space: nowrap;
}
.dropdown-menu li > *:hover, .dropdown-menu li > a:hover {
  background: #FEF9F3;
  color: inherit;
}
.dropdown-menu li .cust_icon {
  width: 24px;
  height: 24px;
}

.widthFull {
  width: 100% !important;
}

.notificationDropdown {
  width: 100%;
  padding: 0;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .notificationDropdown {
    width: 400px;
  }
}

.icon_holder {
  flex-shrink: 0;
  margin-right: 7.5px;
  opacity: 0.35;
}

.nav_item_text {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: none;
}

.caret {
  display: inline-flex;
  margin-left: 4px;
}
.caret:after {
  vertical-align: 0.255em;
  content: "";
  border-top: 0.4em solid;
  border-right: 0.4em solid transparent;
  border-bottom: 0;
  border-left: 0.4em solid transparent;
}

.modal-backdrop {
  background-color: #353535;
}

.modal-content {
  border-radius: 15px;
}

.modal-dialog {
  z-index: 2000;
  margin: 5rem auto;
}

/* Landscape tablets and desktop or laptops */
@media only screen and (min-width: 992px) {
  .modal-md {
    max-width: 650px;
  }

  .modal-lg, .modal-xl {
    max-width: 1170px;
  }
}
.modal-open .modal {
  background-color: rgba(53, 53, 53, 0.9);
}
.modal .modal-header {
  border-bottom: none;
  padding: 10px 15px;
  *zoom: 1;
  justify-content: flex-start;
  /* Tablets and phablets */
}
.modal .modal-header:before, .modal .modal-header:after {
  content: "";
  display: table;
}
.modal .modal-header:after {
  clear: both;
}
@media only screen and (min-width: 768px) {
  .modal .modal-header {
    padding: 15px 30px;
  }
}
.modal .modal-body {
  position: relative;
  padding: 20px 15px;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .modal .modal-body {
    padding: 30px;
  }
}
.modal .subtitle {
  margin-bottom: 15px;
  display: block;
}
.modal .content-panel {
  min-height: 250px;
  max-height: 350px;
  overflow-y: auto;
}
.modal .modal-footer {
  border-top: none;
  padding: 10px 15px;
}

.modal-title {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: -0.015em;
}

.panel-group {
  margin: 0;
}
.panel-group .panel {
  box-shadow: unset;
}
.panel-group .panel + .panel {
  margin: 0;
}

.panel {
  border: none;
  background-color: transparent;
}

.panel-heading {
  padding: 0 0 5px;
  border-bottom: 1px solid #d5d5d5;
}
.panel .panel-heading {
  border-bottom: 1px solid #d5d5d5;
  padding: 15px 0;
}

.panel-title {
  font-size: 16px;
}
.panel-title > a {
  font-size: 1em;
}
.panel-title > a::after {
  content: "-";
  float: right;
  font-size: 21px;
  line-height: 1;
  font-weight: normal;
}
.panel-title > a.collapsed::after {
  content: "+";
}
.panel-title > a:hover, .panel-title > a:focus {
  text-decoration: none;
  color: inherit;
  opacity: 1;
}
.panel-title > a:active, .panel-title > a:visited {
  outline: none;
}

.accordion-toggle {
  display: block;
  cursor: pointer;
}
.principlePanelHeader .accordion-toggle {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
}
.principlePanelHeader .accordion-toggle::before {
  content: "-";
  font-size: 21px;
  line-height: 1;
  font-weight: normal;
  flex-shrink: 0;
  margin-right: 10px;
  width: 25px;
  height: 25px;
  justify-content: center;
  text-align: center;
  background-color: #ff9000;
  border-radius: 50%;
  color: #f5f5f5;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.principlePanelHeader .accordion-toggle.collapsed::before {
  content: "+";
  color: #555555;
  background-color: #E5E5E5;
}

.panel-body {
  padding: 15px 0;
}

.casePrinciple {
  display: block;
  clear: both;
  padding: 15px 0;
}
.casePrinciple + .casePrinciple {
  border-top: 1px solid #d5d5d5;
}

.panelAlign {
  padding-left: 35px;
}

.principlePanelHeader {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.principleItemTitleHolder {
  width: 100%;
}

.principleLegalHead {
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  text-transform: uppercase;
  color: #959595;
}

.principleIssue {
  font-size: 16px;
  color: #555555;
  font-weight: 700;
  -webkit-transition: all 0.4s linear;
  -moz-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}
.collapsed .principleIssue {
  overflow: hidden;
  text-overflow: ellipsis;
  height: 3em;
  line-height: 1.5em;
}

/* Tables */
table {
  width: 100%;
}

.table {
  margin-bottom: 0;
}
.table > thead > tr > td, .table > thead > tr > th, .table tbody > tr > td, .table tbody > tr > th {
  color: inherit;
  border: none;
  padding: 12px 15px;
  vertical-align: middle;
}
.table > thead > tr > th {
  font-size: 11px;
  text-transform: uppercase;
  color: #959595;
  border-bottom: 1px solid #d5d5d5;
}
.table .table {
  background-color: transparent;
}

th.mat-sort-header-sorted {
  color: #ff9000;
}

tr.mat-header-row {
  height: auto !important;
}

.mat-input-element {
  color: #ff9000;
}

.mat-sort-header-button {
  white-space: nowrap;
  text-transform: uppercase;
}

.mat-checkbox-background, .mat-checkbox-frame {
  display: none;
  opacity: 0;
}

.title_block {
  width: 25%;
  max-width: 350px;
}

.table_description {
  min-width: 250px;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .table_description {
    font-size: 16px;
  }
}

.table-responsive {
  border: none;
}

.table-hover > tbody > tr {
  cursor: pointer;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  background-color: white;
  border-bottom: 1px solid #E5E5E5;
  -webkit-transition: all 0.4s ease-in-out;
  -webkit-transition-delay: 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.table-brand .table-hover > tbody > tr > td {
  border-right: 1px solid #E5E5E5;
  vertical-align: middle;
}
.table-brand .table-hover > tbody > tr > td:last-child {
  border-right: none;
}
.table-hover > tbody > tr:hover {
  background-color: #FEF9F3;
}

.invoice_table {
  border-collapse: collapse;
}

.lp_table_row {
  border-bottom: 0;
}
.lp_table_row:last-of-type {
  border-bottom: 1px solid #d5d5d5;
}
.lp_table_header .lp_table_row {
  background-color: #E5E5E5;
}
.invoice_templateItems .lp_table_header .lp_table_row {
  background-color: transparent;
}

.lp_table_cell {
  line-height: 26px;
  /* Tablets and phablets */
}
.invoice_table > .lp_table_body > .lp_table_row > .lp_table_cell {
  padding: 0;
}
@media only screen and (min-width: 768px) {
  .invoice_templateItems .lp_table_cell:first-child {
    padding-left: 30px;
  }
  .invoice_templateItems .lp_table_cell:last-child {
    padding-right: 30px;
  }
}
.invoice_table .lp_table_cell {
  padding-left: 5px;
  padding-right: 5px;
}
.lp_table_header .lp_table_cell {
  padding-top: 17px;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 13px;
  text-transform: capitalize;
  color: #353535;
  border-bottom: 2px solid #d5d5d5;
}

.post_control {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.card_control_item {
  display: inline-block;
}
.card_control_item + .card_control_item::before {
  content: "|";
  color: #E5E5E5;
  padding: 0;
}
.card_control_item > a, .card_control_item > button {
  padding: 4px 12px;
  display: inline-block;
  font-size: 12px;
  border-radius: 25px;
  color: #ff9000;
  fill: #959595;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.card_control_item > a:hover, .card_control_item > a:focus, .card_control_item > button:hover, .card_control_item > button:focus {
  background-color: #ff9000;
  color: #f5f5f5;
  fill: white;
}

.grouped_list_item {
  display: block;
  clear: both;
  border-top: 1px solid #d5d5d5;
}
.grouped_list_item:last-child {
  border-bottom: 1px solid #d5d5d5;
}

.collapse_stat_indicator {
  left: 5px;
  line-height: 0;
  opacity: 0;
  position: absolute;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  fill: #959595;
  -webkit-transition: all 0.4s ease-in-out;
  -webkit-transition-delay: 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.list_header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 0px 10px 25px;
  border-radius: 5px;
  -webkit-transition: all 0.4s ease-in-out;
  -webkit-transition-delay: 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.list_header:hover, .list_header:focus {
  color: #353535;
  background-color: #E5E5E5;
}
.list_header:hover .collapse_stat_indicator, .list_header:focus .collapse_stat_indicator {
  opacity: 1;
}
.list_header .accordion-toggle {
  width: 100%;
  color: inherit;
}
.list_header .accordion-toggle .state_icon {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 0.4s ease-in-out;
  -webkit-transition-delay: 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.list_header .accordion-toggle.collapsed .state_icon {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.list_header .accordion-toggle.collapsed .collapse_stat_indicator {
  opacity: 1;
}

.list_panel_body {
  padding: 20px 10px;
}
.list_panel_body.show {
  border-top: 1px solid #d5d5d5;
}

.list_title {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 600;
}

.list_status {
  position: absolute;
  right: 10px;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #757575;
  font-size: 12px;
}

/*New psot drawer*/
.post_modal_drawer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
  pointer-events: none;
}
.is_active .post_modal_drawer {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.alert_drawer.is_active {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.post_modal_drawer_backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
  background-color: rgba(53, 53, 53, 0.85);
  z-index: -1;
}

.post_modal_drawer_content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  width: 100%;
  height: 100%;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .post_modal_drawer_content {
    width: 40%;
    min-width: 400px;
    max-height: 100%;
    box-shadow: 0 1px 4px 0 rgba(53, 53, 53, 0.04), 0 2px 2px 0 rgba(53, 53, 53, 0.08);
  }
  .alert_drawer .post_modal_drawer_content {
    max-width: 400px;
    min-width: auto;
  }
}

.drawer_content_wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  cursor: default;
  flex-direction: column;
  background-color: white;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%;
}

.drawer_header, .drawer_body, .drawer_footer {
  padding: 15px;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .drawer_header, .drawer_body, .drawer_footer {
    padding-left: 30px;
    padding-right: 30px;
  }
  .alert_drawer .drawer_header, .alert_drawer .drawer_body, .alert_drawer .drawer_footer {
    padding: 15px;
  }
}

.drawer_body {
  clear: both;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 0;
  padding-bottom: 100px;
}
.alert_drawer .drawer_body {
  height: 100%;
}

.drawer_footer {
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid #E5E5E5;
  background-color: white;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.invoice_widget {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
}

.invoice_widget_header {
  width: 100%;
  margin-bottom: 25px;
}

.widget_collapse_header {
  padding: 10px 15px;
  background-color: #d5d5d5;
}

.widget_collapse {
  border-radius: 10px;
  border: 1px solid #d5d5d5;
  overflow: hidden;
}

.widget_body {
  background-color: white;
  padding: 20px 15px;
}

.invoice_detail_column {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 15px;
}

.details_col_info {
  width: 100%;
}
.details_col_info + .details_col_info {
  margin-top: 15px;
}

.details_col_cta {
  margin-top: 15px;
}

.widget_cta_link {
  cursor: pointer;
  font-weight: 600;
  letter-spacing: -0.015em;
  color: #ff9000;
  font-size: 14px;
  display: inline-block;
}
.widget_cta_link:hover, .widget_cta_link:focus, .widget_cta_link:active {
  outline: none;
  text-decoration: underline;
  color: #353535;
}

.inline_seperator {
  color: #959595;
  font-size: 16px;
  vertical-align: middle;
  padding: 0 5px;
  display: inline-block;
}

.logo_placeholder {
  border-radius: 10px;
  background-color: #f5f5f5;
  padding: 20px;
  max-width: 250px;
  position: relative;
  overflow: hidden;
  text-align: center;
}
.logo_placeholder img {
  max-width: 185px;
  display: inline-block;
}

.invoice_widgetcontent {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #d5d5d5;
}

.invoice_widgetContent_header {
  padding: 20px 15px;
}

.menuList_trigger_box {
  max-width: 320px;
}

.select_menu_trigger {
  display: flex;
  height: 160px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  font-size: 16px;
  font-weight: 600;
}
.select_menu_trigger:hover, .select_menu_trigger:focus {
  background-color: #FEF9F3;
  border-color: #ff9000;
}

@keyframes panel-slide-down {
  0%, 30% {
    margin-top: -8px;
    opacity: 0;
  }
  to {
    margin-top: 0;
    opacity: 1;
  }
}
.menuList_holder {
  position: relative;
  outline: none;
}
.tax_add_control .menuList_holder {
  width: 174px;
}

.expense_select {
  margin-left: 30px;
  margin-right: 30px;
}

.menuSelect_input {
  display: inline-block;
  box-sizing: border-box;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  background-color: white;
  padding: 8px 10px;
  -webkit-transition: border 0.6s ease-in-out;
  -moz-transition: border 0.6s ease-in-out;
  -o-transition: border 0.6s ease-in-out;
  transition: border 0.6s ease-in-out;
  min-height: 40px;
  width: 100%;
  position: relative;
  padding-right: 25px;
}
.menuSelect_input:hover, .menuSelect_input:focus {
  border-color: #ff9000;
}

.menuSelect_label {
  display: inline-block;
  width: 100%;
  font-style: italic;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
}

.menuSelect_inputIcon {
  color: #959595;
  position: absolute;
  top: 0px;
  height: 100%;
  right: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.widget_select_menu {
  box-sizing: border-box;
  display: block;
  visibility: hidden;
  position: absolute;
  z-index: 1000;
  min-width: 100%;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.2);
  transform-origin: 0 0;
  height: 0;
  overflow: hidden;
  right: 0;
  left: 0;
  top: 0;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}
.dropdown-menu .widget_select_menu {
  position: relative;
  width: 100%;
  visibility: visible;
  height: auto;
  box-shadow: unset;
  box-shadow: 0 0 0 transparent;
  -webkit-animation: panel-slide-down 0.1s ease-in-out forwards;
  animation: panel-slide-down 0.1s ease-in-out forwards;
}
.menu_is_open .widget_select_menu {
  visibility: visible;
  height: auto;
  -webkit-animation: panel-slide-down 0.1s ease-in-out forwards;
  animation: panel-slide-down 0.1s ease-in-out forwards;
}

.widget_select_header {
  padding: 10px;
}

.select_menu_search {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.search_icon {
  vertical-align: middle;
  margin-right: -20px;
  stroke: #d5d5d5;
  stroke-width: 2;
  position: relative;
  z-index: 10;
  left: 10px;
}

.widget_select_body {
  box-sizing: border-box;
  max-height: 500px;
  overflow: auto;
}

.widget_select_footer {
  text-align: center;
  border-top: 1px solid #E5E5E5;
}
.widget_select_footer > a {
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  display: block;
  line-height: 21px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.widget_select_footer > a:hover, .widget_select_footer > a:focus {
  background-color: #ff9000;
  color: #f5f5f5;
}

.invoice_widgetContent_body {
  margin-top: 15px;
  position: relative;
}
.invoice_widgetContent_body p {
  margin-right: 15px;
}

.invoice_item_name {
  width: 200px;
}

.cell_small {
  width: 100px;
}

.menu_trigger button {
  padding: 10px 15px;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.menu_trigger button:hover, .menu_trigger button:focus {
  background-color: #FEF9F3;
}

.invoice_total_block {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin: 20px 0;
  justify-content: flex-end;
}

.invoice_total_amount {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-right: 30px;
}

.invoice_amount_row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  line-height: 42px;
}
.invoice_templateTotals_taxes .invoice_amount_row {
  line-height: normal;
}

.invoice_amount_label {
  max-width: 500px;
  margin-right: 5px;
}

.invoice_amount_value {
  width: 150px;
  overflow-wrap: break-word;
}

.invoice_tax_section {
  display: flex;
  align-items: flex-end;
}

.invoice_item_taxes {
  flex: 1;
}

.tax_item_row {
  display: flex;
  flex-flow: row nowrap;
}
.tax_item_row + .tax_item_row {
  margin-top: 15px;
}

.tax_add_control {
  display: flex;
  align-items: center;
  flex-flow: row;
  margin-left: auto;
}

.tax_add_label {
  margin-right: 10px;
}

.tax_item_amount {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 150px;
  padding-left: 10px;
  padding-right: 4px;
}

.tax_item_remove {
  display: flex;
  align-items: center;
  width: 40px;
  justify-content: flex-end;
}

.expense_item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 7.5px 0;
}

.expense_item_col {
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.4;
}

.expense_item_info {
  flex-grow: 1;
}

.expense_price {
  width: 100px;
  flex-shrink: 0;
  text-align: right;
  color: #353535;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .expense_price {
    font-size: 16px;
  }
}

.invoice_widgetContent_footer {
  border-top: 1px solid #d4dde3;
  padding: 20px 15px;
}

.invoice_view_summary {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin-bottom: 20px;
}

.invoice_summary_item + .invoice_summary_item {
  margin-left: 30px;
}

.invoice_customer {
  flex-grow: 2;
}

.invoice_viewStep_header {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}

.invoice_stepBox {
  counter-increment: steps;
}

.step_highlighted {
  box-shadow: 0 8px 21px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  border-color: transparent;
  background-color: white;
}

.invoice_viewStep_verticalLine {
  margin: -15px 0 0 39px;
  border: 2px solid #d5d5d5;
  height: 18px;
  width: 0;
}

.step_badge {
  color: #353535;
  float: left;
  font-size: 21px;
  line-height: 50px;
  margin-right: 18px;
  text-align: center;
  border-width: 2px;
}

.ste_badgeInner {
  height: 40px;
  width: 40px;
  margin: 0 6px;
  border-width: 2px;
  border-style: solid;
  line-height: 36px;
  border-radius: 50%;
  border-color: #d5d5d5;
  color: #959595;
}
.step_highlighted .ste_badgeInner {
  border-color: #ff9000;
  color: #353535;
}
.step_done .ste_badgeInner {
  color: #f5f5f5;
  background: #ff9000;
  border-color: #ff9000;
  line-height: 38px;
}

.step_badgeLabel::before {
  content: counter(steps);
}
.step_done .step_badgeLabel::before {
  content: "✓";
}

.invoice_viewStep_options {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  flex: 1;
}

.invoice_viewStep_info {
  flex: 1;
}

.invoice_viewStep_action {
  margin-left: 10px;
  visibility: hidden;
}
.step_done .invoice_viewStep_action, .step_highlighted .invoice_viewStep_action, .box_filled_normal .invoice_viewStep_action {
  visibility: visible;
}

.invoice_viewStep_label {
  font-size: 23px;
  line-height: 32px;
  color: #959595;
}
.step_done .invoice_viewStep_label, .step_highlighted .invoice_viewStep_label {
  color: #757575;
}

.invoice_paymentContent, .invoice_viewStep_body {
  padding-left: 70px;
}

.invoice_paymentContent {
  display: none;
}
.step_highlighted .invoice_paymentContent {
  display: block;
}

.invoice_viewStep_body {
  opacity: 0.25;
}
.invoicePayment_box.step_highlighted .invoice_viewStep_body {
  display: none;
}
.step_done .invoice_viewStep_body {
  opacity: 1;
}

.info_dataRow {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.dataRow_label {
  font-weight: bold;
}

.dataRow_value {
  margin-left: 5px;
}

.invoice_preview_body {
  margin: 20px auto;
  width: 100%;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
}

.invoice_templateWrapper {
  font-size: 14px;
  position: relative;
  min-height: 1024px;
  box-sizing: border-box;
  padding-bottom: 200px;
  background: white;
}

.invoice_templateHeader {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  height: 170px;
  justify-content: space-between;
  background-color: #03211D;
}

.invoiceHeader_block {
  display: flex;
  align-items: center;
  padding: 0 15px;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .invoiceHeader_block {
    padding: 0 30px;
  }
}

.invoice_headerLabel {
  width: 60%;
}

.invoice_headerAmount {
  width: 40%;
  min-width: 150px;
  background-color: #04312b;
}

.invoice_templateMeta {
  padding: 15px;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .invoice_templateMeta {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.invoice_templateTotals {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin: 25px 0;
}

.invoice_templateTotals_blanks {
  flex-grow: 5;
}

.invoice_templateTotals_amounts {
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-right: 15px;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .invoice_templateTotals_amounts {
    margin-right: 30px;
  }
}

.lb_divider {
  margin: 10px auto;
  display: block;
  width: 100%;
  height: 0;
  padding: 0;
  border-bottom: 3px solid #d5d5d5;
  overflow: hidden;
}

.invoice_templateFooter {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 1px solid #E5E5E5;
}

.businessInfo {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 25px 15px;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .businessInfo {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.invoice_templateMemo {
  padding: 25px 15px;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .invoice_templateMemo {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.businessInfo_block {
  flex-grow: 1;
}

.businessInfo_logo img {
  max-width: 200px;
  max-height: 80px;
}

.invoice_view_navAction {
  margin-bottom: 20px;
}

.invoice_paymentStatus {
  display: block;
  margin-bottom: 15px;
}

.invoice_paymentReminders {
  padding-top: 15px;
  border-top: 1px solid #d5d5d5;
}

.widget_narrow {
  max-width: 720px;
  margin: 0 auto;
}

.listHeader {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 5px 0 10px;
}

.auth_panel {
  max-width: 450px;
  margin: auto;
}

.block_panel {
  width: 100%;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  border: 1px solid #E5E5E5;
  box-shadow: 1px 1px 8px rgba(53, 53, 53, 0.15);
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .block_panel {
    padding: 40px;
  }
}

.auth_foot_link {
  margin-top: 30px;
}
.auth_foot_link + .disclaimer_block {
  border-top: 1px solid #d5d5d5;
}
.auth_foot_link p {
  font-size: 12px !important;
}

.pane_header {
  margin-bottom: 30px;
}

.error_message {
  display: block;
  padding: 10px 15px;
  margin-bottom: 30px;
  clear: both;
  background-color: transparent;
  border: 1px solid #ff9000;
  color: #555555;
  border-radius: 10px;
  font-size: 13px;
}

.text-error {
  font-size: 14px;
  color: red;
}

.disclaimer_block {
  margin-top: 20px;
  padding-top: 20px;
}

.auth_cta {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 40px;
  /* Mobile devices */
  /* Tablets and phablets */
}
.auth_cta button {
  font-size: 12px !important;
}
@media only screen and (max-width: 575px) {
  .auth_cta {
    flex-direction: column;
  }
}
@media only screen and (min-width: 768px) {
  .auth_cta {
    justify-content: space-between;
    text-align: left;
  }
}

.cta_sub {
  margin-top: 15px;
  flex-shrink: 0;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .cta_sub {
    margin-top: 0;
    margin-left: 20px;
    text-align: right;
  }
}

.image_blob {
  text-align: center;
  margin-bottom: 20px;
}
.image_blob img {
  max-width: 128px;
  display: inline-block;
}

a {
  text-decoration: none;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  color: inherit;
}
p a {
  color: #ff9000;
}
a:hover, a:focus {
  color: #ff9000;
  text-decoration: none;
}
a:focus {
  outline: thin dotted #d5d5d5;
}
.no-touch a:focus {
  outline: none;
}

a:link,
button {
  -webkit-tap-highlight-color: rgba(6, 162, 83, 0.35);
  -moz-tap-highlight-color: rgba(6, 162, 83, 0.35);
}

button {
  outline: 0;
  padding: 0;
  border: none;
  box-shadow: none;
  width: auto;
  height: auto;
  color: inherit;
  background: 0 0;
  cursor: pointer;
  text-align: center;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn {
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: 600;
  background-color: transparent;
  color: white;
  fill: #E5E5E5;
  text-transform: uppercase;
  border: 2px solid transparent;
  cursor: pointer;
  display: inline-block;
  position: relative;
  max-width: 100%;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  text-decoration: none !important;
  /* Tablets and phablets */
}
.btn.disabled, .btn:disabled {
  background-color: #959595;
}
.btn + .btn {
  margin-left: 15px;
}
@media only screen and (min-width: 768px) {
  .btn {
    min-width: 100px;
  }
}
.btn:hover, .btn:focus {
  text-decoration: none !important;
  box-shadow: 2px 5px 10px 0 rgba(0, 0, 0, 0.4);
  border-color: transparent;
}
.no-touch .btn:hover, .no-touch .btn:focus {
  outline: none;
}

.btnIcon {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin: 0 5px;
  fill: inherit;
}

.btn-icon {
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  fill: #959595;
  border: 1px solid #c5c5c5;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.lp_table_action .btn-icon {
  width: 30px;
  border: none;
}
.btn-icon.action_delete {
  fill: #959595;
}
.btn-icon.action_delete:hover, .btn-icon.action_delete:focus {
  background-color: transparent;
  fill: #D21F3C;
}
.btn-icon + .btn-icon {
  margin-left: 15px;
}
.btn-icon:hover, .btn-icon:hover {
  background-color: #353535;
  fill: #f5f5f5;
}

.add_btn_rounded {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #d5d5d5;
  fill: #555555;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.add_btn_rounded:hover, .add_btn_rounded:focus {
  background-color: #353535;
  fill: #d5d5d5;
}

.btn-primary {
  background-color: #ff9000;
  fill: white;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .show > .btn-primary.dropdown-toggle {
  background-color: #EA7700;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  background-color: #ff9000;
  color: #f5f5f5;
  border-color: #ff9000;
}
.btn-primary.disabled, .btn-primary:disabled {
  background-color: #959595;
  border-color: transparent;
}

.btn-primary-outline {
  background-color: transparent;
  color: #ff9000;
  border-color: #ff9000;
}
.btn-primary-outline:hover, .btn-primary-outline:focus {
  color: #f5f5f5;
  background-color: #ff9000;
}

.btn-default-outline {
  background-color: transparent;
  color: #353535;
  border-color: #353535;
}
.btn-default-outline:hover, .btn-default-outline:focus {
  color: #f5f5f5;
  background-color: #353535;
  border-color: transparent;
}

.btn-secondary {
  background-color: #353535;
}
.btn-secondary:hover, .btn-secondary:focus {
  background-color: #282828;
}

.btn-inverse {
  border: 2px solid #959595;
  color: #757575;
}
.btn-inverse:hover, .btn-inverse:focus {
  background-color: #353535;
  border-color: transparent;
  color: #f5f5f5;
}

.btn-default {
  background-color: inherit;
  color: #555555;
  fill: #757575;
  box-shadow: unset;
}
.btn-default:hover, .btn-default:focus, .btn-default:visited {
  border-color: transparent;
  background-color: white;
  color: #353535;
}

.btn-sm.btn,
.btn-group-sm > .btn {
  font-size: 12px;
  padding: 8.5px 16px;
  min-width: auto;
}

.btn-lg.btn,
.btn-group-lg > .btn {
  font-size: 14px;
}

.btn-block + .btn-block {
  margin-top: 20px;
}

.btn-link {
  border: none;
  cursor: pointer;
  font-size: 1.4rem;
  box-shadow: unset;
  display: inline-block;
  -webkit-transition: all 0.6s cubic-bezier(0.68, 0.34, 0.15, 1);
  -moz-transition: all 0.6s cubic-bezier(0.68, 0.34, 0.15, 1);
  -o-transition: all 0.6s cubic-bezier(0.68, 0.34, 0.15, 1);
  transition: all 0.6s cubic-bezier(0.68, 0.34, 0.15, 1);
  text-decoration: none;
  color: #ff9000;
  padding: 0;
}
.btn-link:hover, .btn-link:focus {
  color: #353535;
  text-decoration: none;
}
.btn-link:active, .btn-link[disabled] {
  color: #959595;
  outline: none;
}

.btn-card {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  padding: 6.5px 16px;
  font-weight: 700;
  border-radius: 35px;
  background-color: transparent;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.btn-card:focus, .btn-card:active, .btn-card:hover {
  outline: none;
}

.card-primary {
  color: #ff9000;
}
.card-primary:hover, .card-primary:focus {
  background-color: rgba(53, 53, 53, 0.15);
  color: #353535;
}

.card-default {
  color: #555555;
}
.card-default:hover, .card-default:focus {
  background-color: rgba(53, 53, 53, 0.15);
  color: #353535;
}

.card-danger {
  color: #959595;
}
.card-danger:hover, .card-danger:focus {
  background-color: rgba(210, 31, 60, 0.15);
  color: #D21F3C;
}

.breadcrumb {
  padding: 0;
  margin-bottom: 0;
  background-color: transparent;
  align-items: center;
}
.breadcrumb > .active {
  text-transform: none;
  pointer-events: none;
  color: #353535;
}

.breadcrumb-item {
  color: #757575;
  display: flex;
  text-transform: uppercase;
  font-weight: 600;
  align-items: center;
  letter-spacing: -0.015em;
}
.breadcrumb-item + .breadcrumb-item::before {
  padding: 0 7.5px;
  content: "›";
  font-size: 1.35em;
  color: #757575;
  font-weight: 400;
}
.breadcrumb-item > a {
  color: inherit;
}

.nav-tabs {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  border-bottom: none;
  box-shadow: inset 0 -1px 0 #E5E5E5;
}
.nav-tabs + .filter_search, .hero_nav .nav-tabs {
  border-top: none;
}
.filter_menu .nav-tabs {
  margin-bottom: -7.5px;
}
.nav-tabs li {
  text-transform: capitalize;
  line-height: 30px;
}
.nav-tabs a {
  padding: 7.5px 10px;
  font-size: 1em;
  display: block;
  white-space: nowrap;
  opacity: 0.75;
  text-transform: uppercase;
}
.nav-tabs a:hover, .nav-tabs a:focus, .nav-tabs a.active {
  color: #353535;
  box-shadow: inset 0 -2px 0 #959595;
}
.nav-tabs a.active {
  opacity: 1;
  box-shadow: inset 0 -3px 0 #555555;
}
.nav-tabs .active > a {
  box-shadow: inset 0 -3px 0 #555555;
  pointer-events: none;
}
.hero_nav .nav-tabs {
  box-shadow: unset;
}
.hero_nav .nav-tabs a {
  box-shadow: unset;
}
.hero_nav .nav-tabs a:hover, .hero_nav .nav-tabs a:focus {
  box-shadow: unset;
  box-shadow: none;
}
.hero_nav .nav-tabs a.active {
  background-color: #f5f5f5;
  box-shadow: unset;
}

/* pager */
.pager {
  display: block;
  *zoom: 1;
}
.pager:before, .pager:after {
  content: "";
  display: table;
}
.pager:after {
  clear: both;
}
.pager > * {
  vertical-align: middle;
  display: inline-block;
  float: left;
}

.pager_item {
  display: inline-block;
  float: left;
  margin-right: 10px;
}
.pager_item a {
  display: block;
  padding: 4.5px 12px;
  border-radius: 40px;
  text-transform: capitalize;
  color: #353535;
}
.pager_item a:hover {
  background-color: #d5d5d5;
}
.pager_item.active a {
  pointer-events: none;
  font-weight: 700;
  background-color: #d5d5d5;
}

.control_trigger {
  padding: 5px 18px;
  min-width: 80px;
  font-size: 13px;
  line-height: 18px;
  white-space: nowrap;
  color: #757575;
  fill: #959595;
  border-radius: 25px;
  border: 1px solid #959595;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}
.control_trigger > .cust_icon {
  margin-right: 2.5px;
}
.control_trigger:hover, .control_trigger:focus {
  border-color: #ff9000;
}

.nav_flex_row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.nav_flex_row .btn + .btn {
  margin-left: 2rem;
}

.link_icon_list {
  clear: both;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.link_item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(6, 162, 83, 0.15);
  fill: #f5f5f5;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.link_item + .link_item {
  margin-left: 20px;
}
.link_item:hover, .link_item:focus {
  background-color: #ff9000;
  fill: #03211D;
}

.mainHeader {
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  text-align: center;
  z-index: 1000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 60px;
  border-bottom: 1px solid #E5E5E5;
}
.mainHeader [class*=container] {
  height: 100%;
  position: relative;
}

.nav_bar_wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  z-index: 1;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}

.navbar_group {
  width: auto;
  height: 100%;
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  position: relative;
}

.nav_group_left {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  z-index: 5;
  float: left;
  flex-shrink: 0;
}

.nav_group_right {
  float: right;
  justify-content: flex-end;
  z-index: 5;
  flex-shrink: 0;
}

.nav_group_center {
  flex-grow: 1;
  /* Mobile devices */
  /* Landscape tablets and desktop or laptops */
}
@media only screen and (max-width: 575px) {
  .nav_group_center {
    width: 100%;
    position: absolute;
    left: 0;
  }
}
@media only screen and (min-width: 992px) {
  .nav_group_center {
    padding-left: 40px;
  }
}

.brand_img {
  display: block;
}
.pane_header .brand_img {
  margin-bottom: 30px;
}
.mainHeader .brand_img {
  height: 60px;
  float: left;
}

.logo_link {
  padding: 0;
  display: inline-block;
  line-height: 60px;
  width: 130px;
  position: relative;
  height: 100%;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .logo_link {
    width: 200px;
  }
}
.logo_link > img {
  vertical-align: middle;
  display: inline-block;
}

.navMenu {
  vertical-align: middle;
  line-height: 60px;
  *zoom: 1;
  color: rgba(53, 53, 53, 0.7);
  fill: rgba(53, 53, 53, 0.7);
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.navMenu:before, .navMenu:after {
  content: "";
  display: table;
}
.navMenu:after {
  clear: both;
}
.navMenu.menu_alt {
  height: 60px;
}
.navMenu .nav_btn {
  background-color: rgba(255, 144, 0, 0.15);
  fill: #959595;
  color: #959595;
  line-height: 21px;
  padding: 10px;
  border-radius: 50%;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .navMenu .nav_btn {
    border-radius: 25px;
  }
}
.navMenu .nav_btn:hover, .navMenu .nav_btn:focus {
  background-color: rgba(255, 144, 0, 0.35);
  fill: #353535;
  color: #353535;
}

.menuList-item {
  position: relative;
}
.menuList-item.is_button {
  margin-left: 20px;
}

.menu__primary {
  -webkit-transition: all 0.6s cubic-bezier(0.68, 0.34, 0.15, 1);
  -moz-transition: all 0.6s cubic-bezier(0.68, 0.34, 0.15, 1);
  -o-transition: all 0.6s cubic-bezier(0.68, 0.34, 0.15, 1);
  transition: all 0.6s cubic-bezier(0.68, 0.34, 0.15, 1);
  transform-origin: top;
  /* Tablets and phablets */
  /* Tablets and phablets */
}
@media only screen and (max-width: 767px) {
  .menu__primary {
    left: 0;
    width: 100%;
    position: fixed;
    height: 100%;
    visibility: hidden;
    top: 60px;
    flex-direction: column;
    background-color: white;
    border-top: 2px solid #ff9000;
    -webkit-transform: scaleY(0);
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
    transform: scaleY(0);
  }
}
.is-active .menu__primary {
  -webkit-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  transform: scaleY(1);
  visibility: visible;
  -webkit-transition: -webkit-transform 0.8s cubic-bezier(0.68, 0.34, 0.15, 1);
  -moz-transition: -moz-transform 0.8s cubic-bezier(0.68, 0.34, 0.15, 1);
  -o-transition: -o-transform 0.8s cubic-bezier(0.68, 0.34, 0.15, 1);
  transition: transform 0.8s cubic-bezier(0.68, 0.34, 0.15, 1);
}
@media only screen and (min-width: 768px) {
  .menu__primary {
    width: auto;
    flex-direction: row;
  }
}

.menu__link_item:not(.btn) {
  display: block;
  padding-left: 15px;
  padding-right: 15px;
  letter-spacing: -0.015em;
  text-transform: uppercase;
  color: inherit;
}
.active .menu__link_item:not(.btn), .menu__link_item:not(.btn):hover, .menu__link_item:not(.btn):focus {
  color: #353535;
  box-shadow: inset 0 -3px 0 #ff9000;
}
.active .menu__link_item:not(.btn) {
  font-weight: 600;
}

.nav_trigger {
  display: inline-block;
  margin-left: 20px;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .nav_trigger {
    display: none;
  }
}

.primary-nav-trigger {
  position: relative;
}

.menu-icon {
  /* this span is the central line of the menu icon */
  display: block;
  width: 24px;
  height: 1px;
  position: relative;
  background-color: #959595;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
  /* these are the upper and lower lines in the menu icon */
}
.menu-icon::before, .menu-icon:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #959595;
  right: 0;
  -webkit-transition: -webkit-transform 0.3s, top 0.3s, background-color 0s;
  -moz-transition: -moz-transform 0.3s, top 0.3s, background-color 0s;
  -o-transition: -o-transform 0.3s, top 0.3s, background-color 0s;
  transition: transform 0.3s, top 0.3s, background-color 0s;
}
.menu-icon::before {
  top: -7px;
}
.menu-icon::after {
  top: 7px;
}
.menu-icon.is-clicked {
  background-color: rgba(255, 144, 0, 0);
}
.menu-icon.is-clicked::before, .menu-icon.is-clicked::after {
  background-color: #ff9000;
}
.menu-icon.is-clicked::before {
  top: 0;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}
.menu-icon.is-clicked::after {
  top: 0;
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -o-transform: rotate(225deg);
  transform: rotate(225deg);
}

.navbar_search_form {
  background-color: white;
  width: 100%;
  height: 60px;
  padding: 8px 10px;
  z-index: 1;
  visibility: hidden;
  -webkit-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  transform: scaleY(0);
  transform-origin: top;
  -webkit-transition: all 0.6s cubic-bezier(0.68, 0.34, 0.15, 1);
  -moz-transition: all 0.6s cubic-bezier(0.68, 0.34, 0.15, 1);
  -o-transition: all 0.6s cubic-bezier(0.68, 0.34, 0.15, 1);
  transition: all 0.6s cubic-bezier(0.68, 0.34, 0.15, 1);
  /* Mobile devices */
}
.listNavtem .navbar_search_form {
  height: 40px;
}
.show_search .navbar_search_form {
  -webkit-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  transform: scaleY(1);
  visibility: visible;
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.68, 0.34, 0.15, 1);
  -moz-transition: -moz-transform 0.6s cubic-bezier(0.68, 0.34, 0.15, 1);
  -o-transition: -o-transform 0.6s cubic-bezier(0.68, 0.34, 0.15, 1);
  transition: transform 0.6s cubic-bezier(0.68, 0.34, 0.15, 1);
}
@media only screen and (min-width: 576px) {
  .navbar_search_form {
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
    visibility: visible;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    background: transparent;
    position: relative;
    max-width: 400px;
  }
  .listNavtem .navbar_search_form {
    max-width: 100%;
    padding: 0;
  }
}

.navbar_search_input {
  background-color: white;
  border-radius: 40px;
  color: #353535;
  border: 1px solid #E5E5E5;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0 30px;
}
.listNavtem .navbar_search_input {
  padding-left: 15px;
}
.navbar_search_input:focus {
  outline: none;
  border-color: #ff9000;
}

.navbar_search_btn {
  margin-top: -10px;
  border-radius: 0;
  position: absolute;
  top: 50%;
  right: auto;
  bottom: auto;
  left: auto;
  fill: #959595;
}
.navbar_search_btn:hover, .navbar_search_btn:focus {
  fill: #ff9000;
}

.navbar_search_submit {
  left: 20px;
}
.listNavtem .navbar_search_submit {
  right: 20px;
  left: auto;
}

.navbar_search_reset {
  right: 13px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.has_value .navbar_search_reset {
  opacity: 1;
  visibility: visible;
}

.control_btn {
  margin-left: 20px;
  position: relative;
  /* Tablets and phablets */
}
.control_btn > a:not(.nav_btn) {
  fill: #959595;
  color: #959595;
}
.control_btn > a:not(.nav_btn):hover, .control_btn > a:not(.nav_btn):focus {
  fill: #353535;
  color: #353535;
}
@media only screen and (min-width: 768px) {
  .control_btn {
    margin-left: 40px;
  }
}

.btn_search_trigger {
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .btn_search_trigger {
    display: none;
  }
}

.badge_count {
  padding: 0.5em;
  display: inline-block;
  background-color: #ff9000;
  color: #f5f5f5;
  font-size: 9px;
  line-height: 7.5px;
  border-radius: 25px;
  vertical-align: super;
  margin-left: -8px;
}

.control_label {
  margin-left: 5px;
  /* Tablets and phablets */
}
@media only screen and (max-width: 767px) {
  .control_label {
    display: none;
  }
}

.formWidgetHeader {
  left: 0px;
  position: fixed;
  right: 0px;
  top: 0px;
  width: 100%;
  z-index: 99;
  background-color: white;
  border-bottom: 1px solid #d5d5d5;
}

.widgetHeaderWrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  height: 60px;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
  z-index: auto;
  min-height: 60px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  overflow: visible;
}

.formNavGroup {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex: 1 0 auto;
  align-items: center;
}

.navGroupLeft {
  -webkit-box-pack: start;
  justify-content: flex-start;
}

.navGroupCenter {
  flex-grow: 0;
  flex-shrink: 0;
  -webkit-box-pack: center;
  justify-content: center;
}

.navGroupRight {
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.navGroupItem {
  padding-left: 10px;
  padding-right: 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  text-transform: uppercase;
  height: 100%;
  background-color: transparent;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-transition: all 0.4s ease-in-out;
  -webkit-transition-delay: 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  box-sizing: border-box;
  fill: #959595;
  font-weight: 700;
  /* Tablets and phablets */
}
.navGroupLeft .navGroupItem {
  border-right: 1px solid #E5E5E5;
}
.navGroupLeft .navGroupItem:hover, .navGroupLeft .navGroupItem:focus {
  background-color: #E5E5E5;
  fill: #ff9000;
}
@media only screen and (min-width: 768px) {
  .navGroupItem {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.formSubmit {
  background-color: #ff9000;
  color: #f5f5f5;
  font-size: 12px;
  /* Tablets and phablets */
}
.formSubmit:hover, .formSubmit:focus {
  background-color: #e68200;
}
@media only screen and (min-width: 768px) {
  .formSubmit {
    padding-right: 45px;
    padding-left: 45px;
  }
}
.formSubmit.disabled, .formSubmit:disabled {
  background-color: #959595;
}

.formProgressBar {
  background-color: transparent;
  height: 2px;
  left: 0px;
  position: absolute;
  right: 0px;
  top: auto;
  z-index: 1;
  bottom: 1px;
}

.perfMeter {
  height: 6px;
  width: 100%;
  min-width: 120px;
}

.progress {
  height: 100%;
  font-size: 0;
  border-radius: 25px;
}

.progress-bar {
  background-color: #ff9000;
  border-radius: 25px;
}
.progress_success .progress-bar {
  background-color: #06A253;
}

.perfTitle {
  font-size: 14px;
  padding-right: 10px;
}

.main_content_wrapper {
  position: relative;
  width: 100%;
  z-index: 2;
  min-height: 400px;
  padding-top: 4px;
  /* Tablets and phablets */
  /* Landscape tablets and desktop or laptops */
}
.main_content_wrapper::before {
  /* never visible - used to check MQ in jQuery */
  display: none;
  content: "mobile";
}
@media only screen and (min-width: 768px) {
  .main_content_wrapper::before {
    /* never visible - used to check MQ in jQuery */
    content: "tablet";
  }
}
@media only screen and (min-width: 992px) {
  .main_content_wrapper {
    margin-top: 0;
  }
  .main_content_wrapper::before {
    /* never visible - used to check MQ in jQuery */
    content: "desktop";
  }
}

.section_block {
  padding: 70px 0;
  display: block;
  width: 100%;
  position: relative;
}

.section_cto {
  margin-top: 30px;
  display: block;
  clear: both;
}

.section_header {
  padding-bottom: 40px;
  position: relative;
}

.section_title {
  font-size: 28px;
  margin-bottom: 15px;
  letter-spacing: -0.015em;
  line-height: 1.25;
  color: #353535;
  /* Tablets and phablets */
}
.cm_page_header .section_title {
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .section_title {
    font-size: 32px;
  }
}

.title {
  letter-spacing: -0.015em;
  line-height: 1.25;
  color: #353535;
  font-size: 16px;
}

.text_clipped {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.title_sm {
  font-size: 18px;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .title_sm {
    font-size: 21px;
  }
}

.title_lg {
  font-size: 24px;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .title_lg {
    font-size: 32px;
  }
}

.title_sub {
  display: inline-block;
  vertical-align: super;
  margin-left: 7.5px;
}

.edit_sub {
  font-size: 11px;
  padding: 5px 10px;
  background-color: #353535;
  color: #f5f5f5;
  border-radius: 5px;
  margin-left: 5px;
  opacity: 0.65;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.edit_sub:hover, .edit_sub:focus {
  opacity: 1;
}

footer[role=contentinfo] {
  width: 100%;
  clear: both;
  color: #d5d5d5;
  background-color: #03211D;
  background: -owg-radial-gradient(farthest-side at 50% 120%, #03211D 0, #0d0d0d 960px);
  background: -webkit-radial-gradient(farthest-side at 50% 120%, #03211D 0, #0d0d0d 960px);
  background: -moz-radial-gradient(farthest-side at 50% 120%, #03211D 0, #0d0d0d 960px);
  background: -o-radial-gradient(farthest-side at 50% 120%, #03211D 0, #0d0d0d 960px);
  background: radial-gradient(farthest-side at 50% 120%, #03211D 0, #0d0d0d 960px);
}

.footerRow {
  display: block;
  padding: 40px 0;
}

.footerTop {
  border-bottom: 1px solid rgba(6, 162, 83, 0.15);
  padding-top: 70px;
}

.brand_media {
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}
.brand_media img {
  display: inline-block;
  max-width: 200px;
}

.footer_block {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  color: #d5d5d5;
  padding-top: 15px;
  padding-bottom: 15px;
}

.ft_block_header {
  margin-bottom: 15px;
}

.item_title {
  font-size: 16px;
  text-transform: capitalize;
  letter-spacing: 0.015;
}

.ft_block_segment + .ft_block_segment {
  margin-top: 15px;
}

.segment_flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.ft_nav_block > li {
  line-height: 2.3em;
}

.copyright_notice {
  color: #E5E5E5;
  padding-top: 15px;
  font-size: 13px;
  opacity: 0.65;
}

.tooltip-inner {
  font-size: 12px;
}

.content_inner {
  padding-bottom: 70px;
}

.section_group {
  display: block;
  margin-bottom: 50px;
}
.section_group:last-child {
  margin-bottom: 0;
}

.section_group_header {
  display: block;
  clear: both;
  margin-bottom: 15px;
}

.info_title {
  font-size: 21px;
  margin-bottom: 20px;
  clear: both;
  color: #353535;
}

.insight_box {
  clear: both;
  margin-bottom: 20px;
  width: 100%;
}

.box_wrapper {
  padding: 15px;
  display: block;
  border-radius: 10px;
  width: 100%;
  border: 1px solid #d5d5d5;
  background-color: transparent;
}
.box_filled_normal .box_wrapper {
  background-color: white;
  border: none;
}

.paddedWrapper {
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
}

.rightSidebarHeader {
  margin-bottom: 20px;
  line-height: 28px;
}

.sectionCTABlock {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin-top: 50px;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  /* Tablets and phablets */
}
@media only screen and (max-width: 767px) {
  .sectionCTABlock {
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }
}

.CTALabel {
  color: #03211D;
  line-height: 165%;
  /* Tablets and phablets */
  /* Tablets and phablets */
}
@media only screen and (max-width: 767px) {
  .CTALabel {
    margin-bottom: 15px;
  }
}
@media only screen and (min-width: 768px) {
  .CTALabel {
    flex-basis: 75%;
  }
}

.infoBanner {
  display: block;
  width: 100%;
}

.bannerWrapper {
  background-color: #ff9000;
  background: -owg-linear-gradient(180deg, #ff9000 50%, #EA7700 100%);
  background: -webkit-linear-gradient(180deg, #ff9000 50%, #EA7700 100%);
  background: -moz-linear-gradient(180deg, #ff9000 50%, #EA7700 100%);
  background: -o-linear-gradient(180deg, #ff9000 50%, #EA7700 100%);
  background: linear-gradient(180deg, #ff9000 50%, #EA7700 100%);
  border-radius: 40px;
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-content: center;
  padding: 20px;
  justify-content: space-between;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .bannerWrapper {
    padding: 40px 80px;
  }
}

.bannerTextHolder {
  width: 81%;
  font-size: 18px;
  padding-right: 3%;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .bannerTextHolder {
    line-height: 165%;
    font-size: 32px;
  }
}

.figure_bg {
  position: absolute;
  right: -15px;
  bottom: 0px;
}
.figure_bg img {
  max-width: 150px;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .figure_bg img {
    max-width: 380px;
  }
}

.customers {
  padding-top: 40px;
}

.client_logos {
  justify-content: center;
  align-content: center;
}

.custLogo {
  padding: 15px;
}

.custLogoWrapper {
  width: 100%;
  text-align: center;
}
.custLogoWrapper img {
  display: inline-block;
  height: 45px;
  width: auto;
  mix-blend-mode: multiply;
}

.dashboard_page_wrapper {
  height: 100%;
  background-color: #f5f5f5;
  padding-top: 60px;
}

.cm_page_frame {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  box-sizing: border-box;
}

.global_alert_holder {
  position: relative;
  z-index: 110;
}

.global_alert {
  background-color: #FEF9F3;
  line-height: 50px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  display: none;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}
.show_alert .global_alert {
  display: block;
  visibility: visible;
  opacity: 1;
}

.cm_page_wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex: 1 1 0%;
  min-height: 0;
  -webkit-transition: -webkit-transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  -moz-transition: -moz-transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  -o-transition: -o-transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  position: relative;
}

.cm_page_sidebar {
  flex-shrink: 0;
  width: 280px;
  background: #03211D;
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
  padding-top: 60px;
  z-index: 100;
  -webkit-transform: translateX(-320px);
  -moz-transform: translateX(-320px);
  -ms-transform: translateX(-320px);
  -o-transform: translateX(-320px);
  transform: translateX(-320px);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  /* Tablets and phablets */
}
.nav_active .cm_page_sidebar {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
@media only screen and (min-width: 768px) {
  .cm_page_sidebar {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

.cm_page_takeover {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: white;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  text-align: center;
  align-items: center;
  z-index: 20000;
  overflow-y: auto;
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
.cm_page_takeover.sessionExpired {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}

.sidebar_wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.cm_page_content {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  flex: 2 0 0%;
  background: #f5f5f5;
  overflow: auto;
  width: 100%;
  height: 100%;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .cm_page_content {
    padding-left: 280px;
  }
}

.cm_user_info, .cm_general_cta {
  width: 100%;
  padding: 15px;
}

.info_toggle {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 60px;
  padding: 0 15px;
  background: rgba(6, 162, 83, 0.15);
  color: #E5E5E5;
  cursor: pointer;
  border-radius: 500px;
}
.info_toggle:hover, .info_toggle:focus {
  background: rgba(6, 162, 83, 0.3);
}
.info_toggle:hover .info_icon, .info_toggle:focus .info_icon {
  -webkit-transform: translateX(3px);
  -moz-transform: translateX(3px);
  -ms-transform: translateX(3px);
  -o-transform: translateX(3px);
  transform: translateX(3px);
}

.user_profile_details {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}

.user_avatar_list {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
  align-items: center;
}
.docControlItem .user_avatar_list {
  margin: 0;
}

.user_avi_item {
  position: relative;
}
.user_avi_item:not(:last-child) {
  margin-right: 10px;
}

.task_assignee_count {
  margin-left: 7.5px;
  font-weight: 600;
  font-size: 12px;
}

.user_info_avi {
  width: 32px;
  height: 32px;
  overflow: hidden;
  flex: 0 0 auto;
  position: relative;
  border-radius: 50%;
  vertical-align: middle;
  border-style: none;
  font-size: 13px;
  box-sizing: border-box;
  color: transparent;
}
.profile_image_thumb .user_info_avi {
  width: 100%;
  height: 100%;
  font-size: 32px;
}
.avi_lg .user_info_avi {
  width: 48px;
  height: 48px;
}
.user_info_avi img {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: auto;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
  border-radius: 50%;
}

.firm_logo {
  margin-bottom: 20px;
}
.firm_logo img {
  display: inline-block;
  max-width: 100px;
}

.avi_sm {
  height: 24px;
  width: 24px;
}

.default_avi {
  width: 100%;
  height: 100%;
  background-color: black;
  text-transform: uppercase;
  color: #f5f5f5;
  border-radius: 50%;
  font-size: 1em;
  line-height: 1em;
  font-weight: 700;
  text-align: center;
  display: block;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user_info {
  flex: 1;
  box-sizing: border-box;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.user_profile_details .user_info {
  overflow: visible;
}
.info_toggle .user_info {
  padding: 0 4px 0 8px;
  width: 80%;
}
.info_toggle .user_info span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.user_profile_details .user_info {
  padding-left: 10px;
}

.hr_user_info {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin-right: 10px;
  width: calc(100% - 40px);
  /* Mobile devices */
}
@media only screen and (max-width: 575px) {
  .hr_user_info {
    flex-direction: column;
  }
}

.user_item + .user_item {
  margin-top: 15px;
}

.username {
  font-size: 18px;
  line-height: 28px;
  display: block;
}

.fullname {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.username_sub {
  flex: 1 0 auto;
  margin-left: 5px;
  color: #757575;
  font-size: 0.85714286em;
}

.info_text {
  font-size: 16px;
  color: #353535;
  /* Mobile devices */
}
@media only screen and (min-width: 576px) {
  .hr_user_info .info_text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    width: 250px;
    margin-right: 10px;
  }
}

.row_caption {
  font-size: 12px;
  color: #959595;
  /* Mobile devices */
}
@media only screen and (min-width: 576px) {
  .hr_user_info .row_caption {
    flex: 1 0 auto;
  }
}

.user_action_link {
  color: #ff9000;
  font-size: 14px;
}
.user_action_link:hover, .user_action_link:focus {
  text-decoration: underline;
}

.info_icon {
  font-size: 14px;
  opacity: 0.4;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  fill: #d5d5d5;
}

.info_widget {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 15px;
  left: 0;
  z-index: 1015;
  min-width: 350px;
  border-radius: 25px;
  background: white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.02), 0 4px 36px 4px rgba(0, 0, 0, 0.1);
  -webkit-transition: left 0.6s ease-in-out, visibility 0.4s ease-in-out, opacity 0.4s ease-in-out;
  -moz-transition: left 0.6s ease-in-out, visibility 0.4s ease-in-out, opacity 0.4s ease-in-out;
  -o-transition: left 0.6s ease-in-out, visibility 0.4s ease-in-out, opacity 0.4s ease-in-out;
  transition: left 0.6s ease-in-out, visibility 0.4s ease-in-out, opacity 0.4s ease-in-out;
}
.widget_open .info_widget {
  opacity: 1;
  visibility: visible;
  left: 20px;
}

.info_widget_body {
  padding: 15px;
  box-sizing: border-box;
}

.info_widget_section {
  margin: 0 10px;
  padding: 15px 0;
  border-bottom: 1px solid #d5d5d5;
}
.info_widget_section:last-child {
  border-bottom: none;
}

.info_widget_menu {
  margin-top: 10px;
}
.info_widget_menu a {
  padding: 7.5px 0;
  display: flex;
  font-size: 16px;
  align-items: center;
}
.info_widget_menu a:hover, .info_widget_menu a:focus {
  text-decoration: underline;
}

.link_icon {
  width: 20px;
  margin-right: 15px;
}

.sec_title {
  font-size: 10px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #959595;
}

.cm_sidebar_navigation {
  overflow-y: auto;
  color: #1c252c;
  height: 100%;
  flex-grow: 2;
}

.cm_sidebar_footer {
  align-items: center;
  padding: 0 15px;
  bottom: 0;
  color: #d5d5d5;
}

.cm_sidebar_inner {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.cm_sidebar_footer .cm_sidebar_inner {
  padding: 15px 0;
  border-top: 1px solid rgba(6, 162, 83, 0.15);
}

.cm_general_cta {
  border-bottom: 1px solid rgba(6, 162, 83, 0.15);
  margin-bottom: 15px;
  padding-top: 0;
}

.sidebar_nav_menu {
  padding: 0;
  margin: 0;
}

.sidebar_nav_menu_item {
  line-height: normal;
}

.sidebar_nav_menu_link {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #959595;
  fill: #959595;
  position: relative;
  padding: 10px 15px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  letter-spacing: 0.1em;
}
.sidebar_nav_menu_link:hover, .sidebar_nav_menu_link:focus, .active .sidebar_nav_menu_link {
  color: #E5E5E5;
  fill: #ff9000;
}
.active .sidebar_nav_menu_link, .show_children .sidebar_nav_menu_link {
  box-shadow: inset 3px 0 #ff9000;
}

.menu_item_icon {
  flex-shrink: 0;
  margin-right: 10px;
  width: 24px;
  text-align: center;
}

.sidebarNavMenuChildren {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out, height 0.4s ease-in-out, visibility 0.4s ease-in-out, opacity 0.4s ease-in-out;
  -moz-transition: -moz-transform 0.6s ease-in-out, height 0.4s ease-in-out, visibility 0.4s ease-in-out, opacity 0.4s ease-in-out;
  -o-transition: -o-transform 0.6s ease-in-out, height 0.4s ease-in-out, visibility 0.4s ease-in-out, opacity 0.4s ease-in-out;
  transition: transform 0.6s ease-in-out, height 0.4s ease-in-out, visibility 0.4s ease-in-out, opacity 0.4s ease-in-out;
}
.show_children > .sidebarNavMenuChildren {
  visibility: visible;
  opacity: 1;
  -webkit-transition: -webkit-transform 0.6s ease-in-out, height 0.4s ease-in-out, visibility 0.4s ease-in-out, opacity 0.4s ease-in-out;
  -moz-transition: -moz-transform 0.6s ease-in-out, height 0.4s ease-in-out, visibility 0.4s ease-in-out, opacity 0.4s ease-in-out;
  -o-transition: -o-transform 0.6s ease-in-out, height 0.4s ease-in-out, visibility 0.4s ease-in-out, opacity 0.4s ease-in-out;
  transition: transform 0.6s ease-in-out, height 0.4s ease-in-out, visibility 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.navMenuChildrenList {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  padding-left: 35px;
  height: 0;
  -webkit-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transition: -webkit-transform 0.6s ease-in-out, height 0.4s ease-in-out;
  -moz-transition: -moz-transform 0.6s ease-in-out, height 0.4s ease-in-out;
  -o-transition: -o-transform 0.6s ease-in-out, height 0.4s ease-in-out;
  transition: transform 0.6s ease-in-out, height 0.4s ease-in-out;
}
.show_children .navMenuChildrenList {
  height: 100%;
  -webkit-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  transform: scaleY(1);
}

.navMenuChildrenLink {
  color: #959595;
  padding: 10px 15px;
  display: block;
  width: 100%;
  white-space: nowrap;
}
.navMenuChildrenLink:hover, .navMenuChildrenLink:focus, .active > .navMenuChildrenLink {
  color: #f5f5f5;
}
.active > .navMenuChildrenLink {
  font-weight: 700;
}

.footer_help_link {
  color: #959595;
  display: block;
  border-radius: 40px;
  background: rgba(6, 162, 83, 0.15);
  font-size: 12px;
  padding: 8px 15px;
  text-align: center;
  letter-spacing: 0.15em;
  margin-right: 15px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.footer_help_link:hover, .footer_help_link:focus {
  background-color: rgba(6, 162, 83, 0.3);
}

.footer_links_seperator {
  opacity: 0.5;
  margin: 0 7.5px;
}

.footer_link_item {
  color: #959595;
}
.footer_link_item:hover, .footer_link_item:focus {
  color: #E5E5E5;
}

.cm_page_body {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .cm_page_body {
    min-height: 0;
    flex: 1 1 0%;
    flex-direction: row;
  }
}

.cdk-live-announcer-element {
  height: 0;
  background: transparent;
  width: 0;
}

.cm_page_body_side_left {
  display: none;
  padding: 30px 0;
  background: transparent;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .cm_page_body_side_left {
    flex-shrink: 0;
    overflow-y: auto;
    width: 250px;
    height: 100%;
    border-right: 1px solid #E5E5E5;
  }
}
.cm_page_body_side_left.is_active {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.pageBodyWithMeta {
  transition: all 0.4s ease-in-out;
  width: 100%;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .pageBodyWithMeta {
    padding-right: 300px;
  }
}
.pageBodyWithMeta.collapse_meta {
  padding-right: 0;
}

.cm_page_body_side_right {
  position: fixed;
  top: 60px;
  width: 300px;
  height: 100%;
  right: 0px;
  padding: 30px 0;
  z-index: 994;
  background-color: #FEF9F3;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.collapse_meta .cm_page_body_side_right {
  right: -300px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.metaSidebarFold {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  z-index: 9;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: -15px;
  width: 15px;
  height: 100%;
  padding-bottom: 70px;
  border-left: 1px solid #E5E5E5;
  background-color: #f5f5f5;
  color: #ff9000;
  text-align: center;
  font-size: 32px;
}
.mailSidebar .metaSidebarFold {
  right: -15px;
  left: auto;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .mailSidebar .metaSidebarFold {
    display: none;
  }
}
.mailSidebar .metaSidebarFold::before {
  content: "‹";
}
.collapse_meta .mailSidebar .metaSidebarFold {
  content: "›";
}
.metaSidebarFold:hover, .metaSidebarFold:focus {
  background-color: #ff9000;
  color: #f5f5f5;
}
.metaSidebarFold::before {
  content: "›";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}
.collapse_meta .metaSidebarFold {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background-color: #FEF9F3;
}
.collapse_meta .metaSidebarFold::before {
  content: "‹";
}

.page_nav_secondary {
  width: 100%;
}

.nav_heading {
  margin-top: 6px;
  padding-left: 30px;
  padding-right: 15px;
  margin-bottom: 30px;
  font-size: 14px;
  color: #959595;
  text-transform: uppercase;
  font-weight: normal;
}

.secondary_nav_menu a {
  display: block;
  padding: 7.5px 15px;
  margin: 0 15px;
  cursor: pointer;
  text-decoration: none;
  border-radius: 5px;
  color: #353535;
}
.secondary_nav_menu a:hover, .secondary_nav_menu a:focus {
  background-color: #E5E5E5;
}
.secondary_nav_menu a.active {
  font-weight: 700;
  background-color: #E5E5E5;
  color: #353535;
}

.cm_page_body_main, router-outlet + * {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
}

.cm_page_body_wrapper {
  width: 100%;
  height: 100%;
  padding: 30px 15px;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
  /* Landscape tablets and desktop or laptops */
}
@media only screen and (min-width: 992px) {
  .cm_page_body_wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.cm_page_hero {
  border-bottom: 1px solid #E5E5E5;
}

.hero_nav {
  margin-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  /* Landscape tablets and desktop or laptops */
}
@media only screen and (min-width: 992px) {
  .hero_nav {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.cm_page_header, .resultBlockHeader {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 30px;
}

.cm_page_breadcrumb_nav {
  padding: 15px;
  /* Landscape tablets and desktop or laptops */
}
@media only screen and (min-width: 992px) {
  .cm_page_breadcrumb_nav {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.cm_header_title {
  flex-grow: 1;
}

.cm_action_primary {
  flex-shrink: 0;
  padding-left: 15px;
}

.cm_page_support_text {
  display: block;
  clear: both;
  margin: -10px 0 30px;
  font-size: 16px;
}

.cm_page_overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  left: 0;
  z-index: 100;
  overflow-y: auto;
  visibility: hidden;
  opacity: 0;
  display: none;
  -webkit-transition: visibility 0.4s ease-in-out, opacity 0.4s ease-in-out;
  -moz-transition: visibility 0.4s ease-in-out, opacity 0.4s ease-in-out;
  -o-transition: visibility 0.4s ease-in-out, opacity 0.4s ease-in-out;
  transition: visibility 0.4s ease-in-out, opacity 0.4s ease-in-out;
  /* Tablets and phablets */
}
.cm_page_overlay.show_overlay {
  display: block;
  opacity: 1;
  visibility: visible;
}
@media only screen and (min-width: 768px) {
  .cm_page_overlay {
    width: calc(100% - 280px);
    left: 280px;
  }
}

.checkout_layout {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: center;
  align-items: stretch;
}

.checkoutPane {
  flex-basis: 100%;
  min-width: 0;
}

.checkoutPane_wrapper {
  position: relative;
  z-index: 1;
  background-color: white;
  border: 1px solid #d5d5d5;
  border-radius: 15px;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2);
}

.checkoutPane_content {
  position: relative;
  padding: 15px;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .checkoutPane_content {
    padding: 30px;
  }
}

.chechoutForm {
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .chechoutForm {
    flex-basis: 65%;
    margin-right: 2.5%;
  }
}

.checkoutSummary {
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .checkoutSummary {
    flex-basis: 32.5%;
  }
}

.detailsBlock {
  padding-top: 30px;
}
.detailsBlock:not(:last-child) {
  padding-bottom: 30px;
  border-bottom: 1px solid #E5E5E5;
}

.summary_block {
  letter-spacing: 0;
  margin-bottom: 0;
  padding: 15px;
  font-size: 13px;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .summary_block {
    padding: 30px;
  }
}

.padding-top-none {
  padding-top: 0 !important;
}

.footerBlock {
  padding-left: 0;
  padding-right: 0;
  border-top: 1px solid #d5d5d5;
}

.priceInfoBlock {
  padding: 20px 0;
}

.priceDetailRow {
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 10px 15px;
  /* Tablets and phablets */
}
@media only screen and (min-width: 768px) {
  .priceDetailRow {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.priceRowLabel {
  margin: 0;
  min-width: 100px;
}

.priceRowValue {
  position: relative;
  text-align: right;
}

.price_suffix {
  display: block;
  font-style: italic;
}

.resultBlock {
  clear: both;
}
.resultBlock:not(:last-of-type) {
  margin-bottom: 40px;
}

.resultBlockHeader {
  margin-bottom: 15px;
  margin-top: 0;
}

.resultMore {
  display: block;
  padding: 10px;
  width: 100%;
  background-color: white;
  color: #ff9000;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: -0.015em;
  text-align: center;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.resultMore:hover, .resultMore:focus {
  background-color: #FEF9F3;
  color: #353535;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
*::before, *::after {
  box-sizing: border-box;
}

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
}