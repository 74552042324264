.dashboard_page_wrapper {
    height: 100%;
    background-color: $color_white;
    padding-top: $header_height;
}

.cm_page_frame {
    @include flexbox();
    flex-direction: column;
    min-height: 100%;
    box-sizing: border-box;
}

.global_alert_holder {
    position: relative;
    z-index: 110;
}

.global_alert {
    background-color: $brand_primary_light;
    line-height: 50px;
    box-shadow: 0 1px 4px rgba(black, .15);
    display: none;
    opacity: 0;
    visibility: hidden;
    @include transition(all .6s ease-in-out);

    .show_alert & {
        display: block;
        visibility: visible;
        opacity: 1;
    }
}

.cm_page_wrapper {
    @include flexbox();
    flex: 1 1 0%;
    min-height: 0;
    @include transition(transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out);
    position: relative;
}

.cm_page_sidebar {
    flex-shrink: 0;
    width: $sidebar_width;
    background: $brand_accent_dark;
    position: fixed;
    height: 100%;
    left: 0;
    top: 0;
    padding-top: $header_height;
    z-index: 100;
    @include transform(translateX(-320px));
    @include transition(all .5s ease-in-out);

    .nav_active & {
        @include transform(translateX(0));
        @include transition(all .5s ease-in-out);
    }

    @include MQ(M) {
        @include transform(translateX(0));
    }
}

.cm_page_takeover {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: white;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    @include flexbox();
    text-align: center;
    align-items: center;
    z-index: 20000;
    overflow-y: auto;
    @include transition(all .6s ease-in-out);
    opacity: 0;
    visibility: hidden;

    &.sessionExpired {
        opacity: 1;
        visibility: visible;
        @include transition(all .6s ease-in-out);
    }
}

.sidebar_wrapper {
    @include flexbox();
    flex-direction: column;
    height: 100%;

    position: relative;
}

.cm_page_content {
    @include flexbox();
    flex-direction: column;
    flex: 2 0 0%;
    background: $color_white;
    overflow: auto;
    width: 100%;
    height: 100%;

    @include MQ(M) {
        padding-left: $sidebar_width;
    }
}

.cm_user_info, .cm_general_cta {
    width: 100%;
    padding: 15px;
}

.info_toggle {
    @include flexbox;
    align-items: center;
    box-sizing: border-box;
    height: 60px;
    padding: 0 15px;
    background: rgba($brand_accent, .15);
    color: $border_grey_light;
    cursor: pointer;
    border-radius: 500px;

    &:hover, &:focus {
        background: rgba($brand_accent, .3);

        .info_icon {
            @include transform(translateX(3px));
        }
    }
}

.user_profile_details {
    @include flexbox();
    align-items: center;
}

.user_avatar_list {
    @include flexbox();
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;
    align-items: center;

    .docControlItem  &{
      margin: 0;
    }
}

.user_avi_item {
    position: relative;

    &:not(:last-child) {
        margin-right: 10px;
    }
}

.task_assignee_count {
    margin-left: 7.5px;
    font-weight: 600;
    font-size: 12px;
}

.user_info_avi {
    width: 32px;
    height: 32px;
    overflow: hidden;
    flex: 0 0 auto;
    position: relative;
    border-radius: 50%;
    vertical-align: middle;
    border-style: none;
    font-size: 13px;
    box-sizing: border-box;
    color: transparent;

    .profile_image_thumb &{
        width: 100%;
        height: 100%;
        font-size: 32px;
    }

    .avi_lg & {
        width: 48px;
        height: 48px;
    }

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        margin: auto;
        @include center(xy);
        z-index: 1;
        border-radius: 50%;
    }
}

.firm_logo {
    margin-bottom: 20px;

    img {
        display: inline-block;
        max-width: 100px;
    }
}

.avi_sm {
    height: 24px;
    width: 24px;
}

.default_avi {
    width: 100%;
    height: 100%;
    background-color: black;
    text-transform: uppercase;
    color: $color_white;
    border-radius: 50%;
    font-size: 1em;
    line-height: 1em;
    font-weight: 700;
    text-align: center;
    display: block;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user_info {
    flex: 1;
    box-sizing: border-box;
    @include flexbox();
    flex-direction: column;
    @include text_truncate();

    .user_profile_details &{
        overflow: visible;
    }

    .info_toggle & {
        padding: 0 4px 0 8px;
        width: 80%;

        span {
            display: block;
            @include text_truncate();
        }
    }

    .user_profile_details & {
        padding-left: 10px;
    }
}

.hr_user_info {
    @include flexbox;
    flex-grow: 1;
    align-items: center;
    margin-right: 10px;
    width: calc(100% - 40px);

    @include MQ-MAX(S) {
        flex-direction: column;
    }
}

.user_item + .user_item {
    margin-top: 15px;
}

.username {
    font-size: 18px;
    line-height: 28px;
    display: block;
}

.fullname {
    font-size: 14px;
    @include text_truncate();
}

.username_sub {
    flex: 1 0 auto;
    margin-left: 5px;
    color: $color_grey_dark;
    font-size: .85714286em;
}

.info_text {
    font-size: 16px;
    color: $brand_black;

    @include MQ(S) {
        .hr_user_info & {
            @include text_truncate();
            width: 250px;
            margin-right: 10px
        }
    }
}

.row_caption {
    font-size: 12px;
    color: $border_grey_dark;

    @include MQ(S) {
        .hr_user_info & {
            flex: 1 0 auto;
        }
    }
}

.user_action_link {
    color: $brand_primary;
    font-size: 14px;

    &:hover, &:focus {
        text-decoration: underline;
    }
}

.info_icon {
    font-size: 14px;
    opacity: 0.4;
    @include transition (all .4s ease-in-out);
    fill: $border_grey;
}

.info_widget {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 15px;
    left: 0;
    z-index: 1015;
    min-width: 350px;
    border-radius: 25px;
    background: white;
    box-shadow: 0 2px 2px rgba(black,0.02), 0 4px 36px 4px rgba(black,0.1);
    @include transition(left .6s ease-in-out, visibility .4s ease-in-out, opacity .4s ease-in-out);

    .widget_open & {
        opacity: 1;
        visibility: visible;
        left: 20px;
    }
}

.info_widget_body {
    padding: 15px;
    box-sizing: border-box;
}

.info_widget_section {
    margin: 0 10px;
    padding: 15px 0;
    border-bottom: 1px solid $border_grey;

    &:last-child {
        border-bottom: none;
    }
}

.info_widget_menu {
    margin-top: 10px;

    a {
        padding: 7.5px 0;
        display: flex;
        font-size: 16px;
        align-items: center;

        &:hover, &:focus {
            text-decoration: underline;
        }
    }
}

.link_icon {
    width: 20px;
    margin-right: 15px;
}

.sec_title {
    font-size: 10px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: $border_grey_dark;
}

.cm_sidebar_navigation {
    overflow-y: auto;
    color: #1c252c;
    height: 100%;
    flex-grow: 2;
}

.cm_sidebar_footer {
    align-items: center;
    padding: 0 15px;
    bottom: 0;
    color: $border_grey;
}

.cm_sidebar_inner {
    @include flexbox();
    align-items: center;
    overflow: hidden;

    .cm_sidebar_footer & {
        padding: 15px 0;
        border-top: 1px solid rgba($brand_accent, .15);
    }
}

.cm_general_cta {
    border-bottom: 1px solid rgba($brand_accent, .15);
    margin-bottom: 15px;
    padding-top: 0;
}


.sidebar_nav_menu {
    padding: 0;
    margin: 0;
}

.sidebar_nav_menu_item {
    line-height: normal;
}

.sidebar_nav_menu_link {
    @include flexbox();
    align-items: center;
    text-decoration: none;
    color: $border_grey_dark;
    fill: $border_grey_dark;
    position: relative;
    padding: 10px 15px;
    @include transition(all .4s ease-in-out);
    letter-spacing: .1em;

    &:hover, &:focus, .active & {
        color: $border_grey_light;
        fill: $brand_primary;
    }

    .active &, .show_children  & {
        box-shadow: inset 3px 0 $brand_primary;
    }
}

.menu_item_icon {
    flex-shrink: 0;
    margin-right: 10px;
    width: 24px;
    text-align: center;
}

.sidebarNavMenuChildren {
    opacity: 0;
    visibility: hidden;
    @include transition(transform .6s ease-in-out, height .4s ease-in-out, visibility .4s ease-in-out, opacity .4s ease-in-out);

    .show_children > &{
        visibility: visible;
        opacity: 1;
        @include transition(transform .6s ease-in-out, height .4s ease-in-out, visibility .4s ease-in-out, opacity .4s ease-in-out);
    }

}

.navMenuChildrenList {
    @include flexbox();
    flex-direction: column;
    padding-left: 35px;
    height: 0;
    @include transform(scaleY(0));
    @include transform-origin(0, 0);
    @include transition(transform .6s ease-in-out, height .4s ease-in-out);

    .show_children &{
        height: 100%;
        @include transform(scaleY(1));
    }
}

.navMenuChildrenLink {
    color: $border_grey_dark;
    padding: 10px 15px;
    display: block;
    width: 100%;
    white-space: nowrap;

    &:hover, &:focus, .active > &{
        color: $color_white;
    }

    .active > & {
        font-weight: 700;
    }
}

.footer_help_link {
    color: $border_grey_dark ;
    display: block;
    border-radius: 40px;
    background: rgba($brand_accent, .15);
    font-size: 12px;
    padding: 8px 15px;
    text-align: center;
    letter-spacing: 0.15em;
    margin-right: 15px;
    @include transition(all 0.4s ease);

    &:hover, &:focus {
        background-color: rgba($brand_accent, .3);
    }
}

.footer_links_seperator {
    opacity: 0.5;
    margin: 0 7.5px;
}

.footer_link_item {
    color: $border_grey_dark;

    &:hover, &:focus {
        color: $border_grey_light;
    }
}

.cm_page_body {
    @include flexbox();
    flex-direction: column;

    @include MQ(M) {
        min-height: 0;
        flex: 1 1 0%;
        flex-direction: row;
    }
}

.cdk-live-announcer-element {
    height: 0;
    background: transparent;
    width: 0;
}

// Secondary page sidebar
.cm_page_body_side_left {
    display: none;
    padding: 30px 0;
    background: transparent;

    @include MQ(M) {
        flex-shrink: 0;
        overflow-y: auto;
        width: 250px;
        height: 100%;
        border-right: 1px solid $border_grey_light;
    }

    &.is_active {
        @include flexbox();
    }
}

.pageBodyWithMeta {
    transition: all 0.4s ease-in-out;
    width: 100%;

    @include MQ(M) {
        padding-right: 300px;
    }

    &.collapse_meta {
        padding-right: 0;
    }
}

.cm_page_body_side_right {
    position: fixed;
    top: $header_height;
    width: 300px;
    height: 100%;
    right: 0px;
    padding: 30px 0;
    z-index: 994;
    background-color: $brand_primary_light;
    @include transition(all .4s ease-in-out);

    .collapse_meta & {
        right: -300px;
        @include transition(all .4s ease-in-out);
    }
}

.metaSidebarFold {
    @include flexbox();
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
    @include transition(all .4s ease-in-out);
    z-index: 9;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: -15px;
    width: 15px;
    height: 100%;
    padding-bottom: 70px;
    border-left: 1px solid $border_grey_light;
    background-color: $color_white;
    color: $brand_primary;
    text-align: center;
    font-size: 32px;

    .mailSidebar & {
        right: -15px;
        left: auto;

        @include MQ(M) {
            display: none;
        }

        &::before {
            content: "\2039";
        }

        .collapse_meta & {
            content: "\203A";
        }
    }

    &:hover, &:focus {
        background-color: $brand_primary;
        color: $color_white;
    }

    &::before {
        content: "\203A";
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
    }

    .collapse_meta & {
        @include transition(all .4s ease-in-out);
        background-color: $brand_primary_light;

        &::before {
            content: "\2039";
        }
    }
}

.page_nav_secondary {
    width: 100%;
}

.nav_heading {
    margin-top: 6px;
    padding-left: 30px;
    padding-right: 15px;
    margin-bottom: 30px;
    font-size: 14px;
    color: $border_grey_dark;
    text-transform: uppercase;
    font-weight: normal;
}

.secondary_nav_menu {
    a {
        display: block;
        padding: 7.5px 15px;
        margin: 0 15px;
        cursor: pointer;
        text-decoration: none;
        border-radius: 5px;
        color: $brand_black;

        &:hover, &:focus {
            background-color: $border_grey_light;
        }

        &.active {
            font-weight: 700;
            background-color: $border_grey_light;
            color: $brand_black;
        }
    }
}

.cm_page_body_main, router-outlet + * {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
}

.cm_page_body_wrapper {
    width: 100%;
    height: 100%;
    padding: 30px 15px;
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;

    @include MQ(L) {
        padding-left: 30px;
        padding-right: 30px;
    }
}

.cm_page_hero {
    border-bottom: 1px solid $border_grey_light;
}

.hero_nav {
    margin-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    @include MQ(L) {
        padding-left: 30px;
        padding-right: 30px;
    }
}

.cm_page_header, .resultBlockHeader {
    @include flexbox();
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 30px;
}

.cm_page_breadcrumb_nav {
    padding: 15px;

    @include MQ(L) {
        padding-left: 30px;
        padding-right: 30px;
    }
}

.cm_header_title {
    flex-grow: 1;
}

.cm_action_primary {
    flex-shrink: 0;
    padding-left: 15px;
}

.cm_page_support_text {
    display: block;
    clear: both;
    margin: -10px 0 30px;
    font-size: 16px;
}


.cm_page_overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(white, .95);
    left: 0;
    z-index: 100;
    overflow-y: auto;
    visibility: hidden;
    opacity: 0;
    display: none;
    @include transition(visibility .4s ease-in-out, opacity .4s ease-in-out);

    &.show_overlay {
        display: block;
        opacity: 1;
        visibility: visible;
    }

    @include MQ(M) {
        width: calc(100% - 280px);
        left: $sidebar_width;
    }
}

// Checkout Layout

.checkout_layout {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    justify-content: center;
    align-items: stretch;
}

.checkoutPane {
    flex-basis: 100%;
    min-width: 0;
}

.checkoutPane_wrapper {
    position: relative;
    z-index: 1;
    background-color: white;
    border: 1px solid $border_grey;
    border-radius: 15px;
    box-shadow: 1px 1px 6px rgba(black, .2);
}

.checkoutPane_content {
    position: relative;
    padding: 15px;

    @include MQ(M) {
        padding: 30px;
    }
}

.chechoutForm {
    @include MQ(M) {
        flex-basis: 65%;
        margin-right: 2.5%;
    }
}

.checkoutSummary {
    @include MQ(M) {
        flex-basis: 32.5%;
    }
}

.detailsBlock {
    padding-top: 30px;

    &:not(:last-child) {
        padding-bottom: 30px;
        border-bottom: 1px solid $border_grey_light;
    }
}

.summary_block {
    letter-spacing: 0;
    margin-bottom: 0;
    padding: 15px;
    font-size: 13px;

    @include MQ(M) {
        padding: 30px;
    }
}

.padding-top-none {
    padding-top: 0 !important;
}

.footerBlock {
    padding-left: 0;
    padding-right: 0;
    border-top: 1px solid $border_grey;
}

.priceInfoBlock {
    padding: 20px 0;
}

.priceDetailRow {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 10px 15px;

    @include MQ(M) {
        padding-left: 30px;
        padding-right: 30px;
    }
}

.priceRowLabel {
    margin: 0;
    min-width: 100px;
}

.priceRowValue {
    position: relative;
    text-align: right;
}

.price_suffix {
    display: block;
    font-style: italic;
}

// Search results block
.resultBlock {
    clear: both;

    &:not(:last-of-type) {
        margin-bottom: 40px;
    }
}

.resultBlockHeader {
    margin-bottom: 15px;
    margin-top: 0;
}

.resultMore {
    display: block;
    padding: 10px;
    width: 100%;
    background-color: white;
    color: $brand_primary;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: -0.015em;
    text-align: center;
    @include transition(all .4s ease-in-out);

    &:hover, &:focus {
        background-color: $brand_primary_light;
        color: $brand_black;
    }
}

// widget

